import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePickerComponent } from 'ng2-date-picker';
import { DatePipe } from '@angular/common';
import { ProcuracoesService } from '../../../../service/procuracoes/procuracoes.service';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

import { PerfilDataService } from '../../../../service/perfil/perfil.service';

@Component({
  selector: 'app-filter',
  templateUrl: 'filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  searchForm: FormGroup;
  visible: string = '';
  inicio: string = '';
  prazoProcuracao: number = 0;
  fim: string = '';
  dataInicial: string = 'Data Inicial';
  dataFinal: string = 'Data Final';
  situacao: string = 'Situação';
  situacoes: Array<any> = [];
  dataInicialValida: boolean = false;
  dataFinalValida: boolean = false;
  searchParams: Object = {
    pagina: 1
  };
  a_vencer: boolean = false;
  valida: boolean = false;
  canceladascontribuinte: boolean = false;
  canceladasprocurador: boolean = false;
  aguardandoaceiteprocurador: boolean = false;
  canceladasPrazo: boolean = false;
  naoAceita: boolean = false;

  labels: any;
  configuracaoData: Object = {
    locale: 'pt-br'
  };

  @Output() onDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Input('itemsPerView') itemsPerView;
  @Input('data') data;

  constructor(
    private fb: FormBuilder,
    private procuracaoService: ProcuracoesService,
    private translate: TranslateService,
    private perfilDataService: PerfilDataService
  ) {
    this.criarFormulario();
    this.setLabels();
  }

  ngOnInit() {
    this.search();
    this.getAmbiente();
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      cpf_cnpj: ''
    });
  }

  next() {
    if (!this.data['ultimaPagina']) {
      this.searchParams['pagina'] = this.data['paginaAtual'] + 1;
      this.search();
    }
  }

  previous() {
    if (!this.data['primeiraPagina']) {
      this.searchParams['pagina'] = this.data['paginaAtual'] - 1;
      this.search();
    }
  }

  selecionarDataInicial() {
    if (typeof this.inicio !== 'undefined') {
      this.dataInicial = this.inicio;
      this.dataInicialValida = true;
    }
  }

  selecionarDataFinal() {
    if (typeof this.fim !== 'undefined') {
      this.dataFinal = this.fim;
      this.dataFinalValida = true;
    }
  }

  toggleVisibility(item: string) {
    if (item === this.visible) this.visible = '';
    else this.visible = item;
  }
  
  getAmbiente() {
    this.perfilDataService
        .getCliente()
        .subscribe(
          res => {
            this.prazoProcuracao = res.resultado['0'].configuracao.prazoProcuracao;
          }
        )

  }

  situacaoFilter(e: any) {
    this.valida = false;
    this.canceladascontribuinte = false;
    this.canceladasprocurador = false;
    this.aguardandoaceiteprocurador = false;
    this.canceladasPrazo = false;
    this.situacoes = [];
    let value = e.target.value;
    this.situacoes.push(value);

    switch (e.target.name) {
      case 'valida':
        this.valida = true;
        this.searchParams['situacao'] = 1;
        break;
      case 'canceladascontribuinte':
        this.canceladascontribuinte = true;
        this.searchParams['situacao'] = 2;
        break;
      case 'canceladasprocurador':
        this.canceladasprocurador = true;
        this.searchParams['situacao'] = 4;
        break;
      case 'aguardando':
        this.aguardandoaceiteprocurador = true;
        this.searchParams['situacao'] = 3;
        break;
      case 'canceladasPrazo':
        this.canceladasPrazo = true;
        this.searchParams['situacao'] = 5;
        break;
      case 'naoAceita':
        this.naoAceita = false;
        this.searchParams['situacao'] = 6;
        break;
    }
    

    if (!e.target.checked) {
      this.situacoes = this.situacoes.filter(val => val !== value);
    }
    if (this.situacoes.length === 1) this.situacao = this.situacoes[0];
    else if (this.situacoes.length >= 1)
      this.situacao = `Situação - (${this.situacoes.length})`;
    else this.situacao = 'Situação';
  }

  pesquisarDataInicial(search: boolean) {
    if (!search) {
      delete this.searchParams['data_inicial'];
      this.resetarData('inicial');
    } else {
      var date = moment(this.inicio).format('YYYY-MM-DD');
      this.searchParams['data_inicial'] = date;
    }
    this.search();
    this.visible = '';
  }

  pesquisarDataFinal(search: boolean) {
    if (!search) {
      delete this.searchParams['data_final'];
      this.resetarData('final');
    } else {
      var date = moment(this.fim).format('YYYY-MM-DD');
      this.searchParams['data_final'] = date;
    }
    this.search();
    this.visible = '';
  }

  pesquisarSituacao() {
    this.search();
    this.visible = '';
  }

  search() {
    this.searchParams['total_pagina'] = this.itemsPerView;
    this.onDataChange.emit(this.searchParams);
  }

  searchCpfCnpj() {
    // console.log('this.searchForm.value: ', this.searchForm.value);
    // this.searchForm['total_pagina'] = this.itemsPerView;
    this.searchParams['total_pagina'] = this.itemsPerView;
    this.searchParams['cpf_cnpj'] = this.searchForm.value.cpf_cnpj;
    this.onDataChange.emit(this.searchParams);
    // this.getAmbiente();
  }


  onKeydown(event) {
    console.log('event.key: ',event.key);
    if (event.key === "Enter") {
      this.searchParams['total_pagina'] = this.itemsPerView;
      this.searchParams['cpf_cnpj'] = this.searchForm.value.cpf_cnpj;
      this.onDataChange.emit(this.searchParams);
    }
  }

  resetarData(item: string) {
    this.visible = '';
    if (item === 'inicial') {
      this.dataInicialValida = false;
      this.dataInicial = 'Data Inicial';
    } else {
      this.dataFinalValida = false;
      this.dataFinal = 'Data Final';
    }
  }

  resetarFiltros(item: string) {
    if (item === 'situacao') {
      this.situacao = 'Situação';
      this.valida = false;
      this.a_vencer = false;
      this.canceladascontribuinte = false;
      this.canceladasPrazo =  false
      this.searchParams = {
        pagina: 1
      };
      this.situacoes = [];
    }
    this.search();
    this.visible = '';
  }

  setLabels() {
    this.labels = {
      dataInicial: this.translate.instant('GLOBAIS.DATAINICIAL'),
      dataFinal: this.translate.instant('GLOBAIS.DATAFINAL'),
      cancelar: this.translate.instant('GLOBAIS.CANCELAR'),
      limpar: this.translate.instant('GLOBAIS.LIMPAR'),
      aplicar: this.translate.instant('GLOBAIS.APLICAR'),
      mensagensEnviadas: this.translate.instant('PROCURACAO.MENSAGEMENVIADAS'),
      situacao: this.translate.instant('PROCURACAO.SITUACAO'),
      valida: this.translate.instant('PROCURACAO.VALIDA'),
      vencida: this.translate.instant('PROCURACAO.VENCIDA'),
      avencer: this.translate.instant('PROCURACAO.AVENCER'),
      canceladas: this.translate.instant('PROCURACAO.CANCELADAS'),
      canceladascontribuinte: this.translate.instant('PROCURACAO.CANCELADASCONTRIBUINTE'),
      canceladasprocurador: this.translate.instant('PROCURACAO.CANCELADASPROCURADOR'),
      aguardandoaceiteprocurador: this.translate.instant('PROCURACAO.AGUARDANDOACEITEPROCURADOR')
    };
  }
}
