import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component'
import { HomeRoutingModule } from './home.routing.module'
import { SharedModule } from '../../shared.module';

@NgModule({
  
  imports: [ 
    HomeRoutingModule,
    CommonModule,
    SharedModule
  ],
  exports: [
  ],
  declarations: [ 
    HomeComponent  
  ],
  providers: [],
})
export class HomeModule { }
