import { HttpClient, HttpHeaders } from '@angular/common/http';

/**
 * Carregamento do SVG com sprite e injeção no HTML para crossbrowser
 * TODO - implementar o carregamento e injeção de multiplos sprites
 */
export function SvgInitializer(
  httpClient: HttpClient,
  configs: any
): () => Promise<any> {
  const svgLogger = 'svg_load';
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      const promisses: Array<any> = [];

      configs.getConfig().svg_sprites.forEach(element => {
        promisses.push(loadAsset(element, httpClient, svgLogger));
      });

      Promise.all(promisses)
        .then(data => {
          resolve();
        })
        .catch(error => {
          console.log('error', error);
          reject(error);
        });
    });
  };
}

/**
 * Controe Promise para carregamento do conteúdo
 * @param url
 * @param httpClient
 * @param logger
 * @param timeLoggerName
 */
const loadAsset = (
  url: string,
  httpClient: HttpClient,
  timeLoggerName: string
) => {
  return new Promise((resolve, reject) => {
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');
    //determina a url do sprite
    const svgUrl = url;
    //carrega svg
    const currentLogger = timeLoggerName + '_' + url;

    httpClient.get(svgUrl, { headers, responseType: 'text' }).subscribe(
      data => {
        const svgDiv = document.createElement('div');
        svgDiv.id = 'svg-sprites';
        svgDiv.className = 'd-none';
        svgDiv.innerHTML = data;
        document.getElementsByTagName('body')[0].appendChild(svgDiv);
        resolve();
      },
      error => {
        console.timeEnd(currentLogger);
        console.error(error);
        reject(error);
      }
    );
  });
};
