import { Component, OnInit, Input } from '@angular/core';
import { WizardService } from '../../../../service/wizard/wizard.service';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: '[app-wizard]',
  templateUrl: 'wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnInit {
  private _totalSteps: number;
  @Input()
  set totalSteps(value: number) {
    for (let index = 0; index < value; index++) {
      this.steps.push('0' + (index + 1).toString());
    }
    this._totalSteps = value;
  }
  get totalSpes(): number {
    return this._totalSteps;
  }

  steps = [];
  subscrition: Subscription;

  active: number = 1;
  constructor(private wizardService: WizardService) {}

  ngOnInit() {
    this.wizardService.index$.subscribe(index => {
      this.active = index;
    });

    if (this.wizardService.userData.tipo === 'fisica') this.steps.pop();
  }
}
