import { AfterViewChecked, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GlobalVariable } from './model/global-variable';
import { Subscription } from 'rxjs/Subscription';
import { UserService } from './service/user-service/user.service';
import { SessionService } from './service/session/session.service';
import { CervelloService } from './service/cervello/cervello.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { MessagingService } from './service/push-notification/messaging.service';

@Component({
  selector: 'my-app',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  host: {
    '(window: resize)': 'resizeDaPagina($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit{
  userInfo: any;
  subscription: Subscription;
  sidebarFechada: boolean;
  message;

  constructor(
    private userService: UserService,
    private titleService: Title,
    private translate: TranslateService,
    private sessionService: SessionService,
    private router: Router,
    private cervelloService: CervelloService,
    private messagingService: MessagingService
  ) {

    try{
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if((<any>window).ga !== undefined){
            (<any>window).ga('set', 'page', event.urlAfterRedirects);
            (<any>window).ga('send', 'pageview');
          } 
        }
      });
    }catch(e){
      // console.log('erro ao enviar analytics', e);
    }

    moment.locale('pt-br');
    this.sidebarFechada = window.innerWidth < 1080;
    this.userInfo = this.sessionService.getSession();

    this.subscription = this.userService.getInfo().subscribe(info => {
      this.sidebarFechada = info.sidebarFechada;
      this.userInfo = info;
    });

    this.titleService.setTitle(translate.instant('GLOBAIS.PAGETITLE'));

  }
  
  ngOnInit() {
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
  }

  resizeDaPagina(evento) {
    evento.target.innerWidth < 1080
    ? this.sidebarFechada = true
    : this.sidebarFechada = this.sidebarFechada;

    let estadoMenu = {};
    estadoMenu['sidebarFechada'] = this.sidebarFechada;
    this.userService.addData(estadoMenu);
  }
}
