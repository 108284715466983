import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs/Observable';
import { UserService } from '../user-service/user.service';
import { SessionService } from '../session/session.service';

import { environment } from '../../../environments/environment'
import { Subscription } from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate {

  private session = this.sessionService.getSession();

  url: string = environment.BASE_URL;
  permissao: Object = null;
  permissoes: Array<any> = []

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private userService: UserService,
    private router: Router) {
  }


  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const expectedRole = route.data.modulo;

    return this.http.get(`${this.url}/obterPermissoesAcesso`).map(data => {
      if (expectedRole === '0') {
        return true;
      } 
      this.permissoes = data['resultado'];
      this.permissao = this.permissoes.find(permissao => permissao.modulo === expectedRole)
      if (!this.permissao) {
        this.permissao = {
          modulo: null
        }
      }
      if (expectedRole === '0') {
        return true;
      } else if (this.permissao['modulo'] == null || this.permissao['modulo'] !== expectedRole || !this.session['loggedIn']) {
        return false;
      } else {
        return true;
      }
    });
  }
}
