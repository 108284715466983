import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfilUserRoutingModule } from './perfil-user.routing.module';
import { SharedModule } from '../../shared.module';
import { PerfilUserComponent } from './pages/perfil.component';
import { CommonAppModule } from '../common/common.module';
import { CpfCnpjPipe } from '../../pipes/cpf-cnpj.pipe';

@NgModule({
  imports: [
    PerfilUserRoutingModule,
    CommonModule,
    SharedModule,
    CommonAppModule,
  ],
  exports: [],
  declarations: [PerfilUserComponent],
  providers: [CpfCnpjPipe],
})
export class PerfilUserModule {}
