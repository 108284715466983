import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: 'not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})

export class NotFoundComponent implements OnInit {
  hasSession: boolean = false;
  constructor(private sessionService: SessionService, private router: Router) { }

  ngOnInit() {
    const session = this.sessionService.getSession();
    session['loggedIn'] ? this.hasSession = true: this.hasSession = false;
   }

   redirect(){
     if(this.hasSession) {
      this.router.navigate(['/home']);
     } else {
      this.router.navigate(['/index']);
     }
   }
}