import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../../shared.module';
import { CommonAppModule } from '../../features/common/common.module'
import {NgxMaskModule} from 'ngx-mask'

import { AutoCadastroComponent } from './auto-cadastro.component';
import { CadastroRoutingModule } from './auto-cadastro.routing.module';
import { WizardComponent } from './componentes/wizard/wizard.component';
import { ValidacaoComponent } from './pages/validacao/validacao.component';
import { ResumoComponent } from './pages/resumo/resumo.component';
import { ResumoContribuinteComponent } from './pages/resumo/componentes/contribuinte/contribuinte.component';
import { ResumoProcuradorComponent} from './pages/resumo/componentes/procurador/procurador.component'
import { RepresentanteLegalComponent } from './pages/representante-legal/representante-legal.component';
import { ListaComponent } from './pages/lista/lista.component';
import { DadosBasicosComponent } from './pages/dados-basicos/dados-basicos.component';
import { DadosProcuradorComponent } from './pages/dados-procurador/dados-procurador.component';
import { CodigoComponent } from './pages/codigo/codigo.component';
import { PopUpCpfContribuinteComponent } from './componentes/pop-up-cpf-contribuinte/pop-up-cpf-contribuinte.component';
import { PopUpTermoAdesaoComponent } from './pages/validacao/componentes/pop-up-termo-adesao/pop-up-termo-adesao.component';
import { CertificadoComponent } from './pages/certificado/certificado.component';
import { IconesValidacaoComponent } from './pages/validacao/componentes/icones-validacao/icones-validacao.component';


@NgModule({
  imports: [ 
    CommonModule, 
    FormsModule, 
    ReactiveFormsModule,  
    HttpClientModule, 
    RouterModule,
    SharedModule,
    CadastroRoutingModule,
    CommonAppModule,
    NgxMaskModule.forRoot()
  ],
  exports: [],
  declarations: [
    AutoCadastroComponent,
    WizardComponent,
    ValidacaoComponent,
    ResumoComponent,
    ResumoProcuradorComponent,
    ResumoContribuinteComponent,
    RepresentanteLegalComponent,
    ListaComponent,
    DadosBasicosComponent,
    DadosProcuradorComponent,
    CodigoComponent,
    PopUpCpfContribuinteComponent,
    PopUpTermoAdesaoComponent,
    CertificadoComponent,
    IconesValidacaoComponent
  ],
  providers: [],
})
export class CadastroModule { }
