import {
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { UserService } from '../../../service/user-service/user.service';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  PRIMARY_OUTLET,
  Params
} from '@angular/router';
import 'rxjs/add/operator/filter';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  config: any = null;
  breadcrumbs: Array<Object> = [];
  userData: any;
  userName: any = ' - ';
  userInscricao: any = 'Não Informado';
  userDoc: any = ' - ';
  userNameLabel: any = ' - ';
  userDocLabel: any = ' - ';
  hasLetters: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.config = this.router.config;
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe(event => {
        this.userData = this.userService.getUserData();

        this.userData &&
        !this.userData.procuracao &&
        this.userData.usuario &&
        this.userData.usuario.detalhe &&
        this.userData.usuario.detalhe.inscricaoMunicipal
          ? (this.userInscricao = this.userData.usuario.detalhe.inscricaoMunicipal)
          : null;

        this.userData &&
        !this.userData.usuario &&
        this.userData.procuracao &&
        this.userData.procuracao.outorgante &&
        this.userData.procuracao.outorgante.detalhe.inscricaoMunicipal
          ? (this.userInscricao = this.userData.procuracao.outorgante.detalhe.inscricaoMunicipal)
          : null;

        this.userData &&
        this.userData.usuario &&
        this.userData.procuracao &&
        this.userData.procuracao.outorgante &&
        this.userData.procuracao.outorgante.detalhe &&
        this.userData.procuracao.outorgante.detalhe.inscricaoMunicipal
          ? (this.userInscricao = this.userData.procuracao.outorgante.detalhe.inscricaoMunicipal)
          : null;

        this.userData && this.userData.usuario
          ? (this.userDoc = this.userData.usuario.cpfCnpj)
          : null;

        this.userData && this.userData.procuracao && !this.userData.usuario
          ? (this.userDoc = this.userData.procuracao.outorgante.cpfCnpj)
          : null;

        this.userData && this.userData.procuracao && this.userData.usuario
          ? (this.userDoc = this.userData.procuracao.outorgante.cpfCnpj)
          : null;

        this.userData && this.userData.usuario
          ? (this.userName = this.userData.usuario.nomeRazaoSocial)
          : null;

        this.userData && this.userData.procuracao && !this.userData.usuario
          ? (this.userName = this.userData.procuracao.outorgante.nomeRazaoSocial)
          : null;

        this.userData && this.userData.procuracao && this.userData.usuario
          ? (this.userName = this.userData.procuracao.outorgante.nomeRazaoSocial)
          : null;

        this.breadcrumbs = [];
        let currentRoute = this.route.root,
          url = '';

        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach(route => {
            if (route.outlet === 'primary') {
              const routeSnapshot = route.snapshot;
              url +=
                '/' + routeSnapshot.url.map(segment => segment.path).join('/');
              this.userService.refreshUrl(url);
              let label = null;
              if (route.snapshot.data.breadcrumb) {
                label = route.snapshot.data.breadcrumb;
                this.breadcrumbs.push({
                  label: label,
                  url: url
                });
              }
              currentRoute = route;
            }
          });
        } while (currentRoute);

        if (this.breadcrumbs.length === 0) {
          this.breadcrumbs.push({
            label: 'Página Inicial',
            url: '/home'
          });
        }
      });
    let letters = JSON.parse(window.localStorage.getItem('hasletters'));
    letters ? (this.hasLetters = true) : (this.hasLetters = false);
  }

  verifyUser() {
    this.userService.getInfo().subscribe(data => {
      this.userData = data;
      this.trocaUser();
      console.log('trocando user em breadcrumb');
    });
  }

  trocaUser() {
    this.userDoc = 'test';
    this.userName = 'test';
    console.log('troca user: ', this.userData);
  }
}
