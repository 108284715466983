import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { Router } from '@angular/router';
import { WizardService } from '../../../service/wizard/wizard.service';
import { CadastroService } from '../../../service/cadastro/cadastro.service';

import { TranslateService } from '@ngx-translate/core';
import swal from "sweetalert2";
import {getSwal} from "../../../utils/swalObject";

@Component({
  selector: 'app-pre-cadastro',
  templateUrl: 'pre-cadastro.component.html',
  styleUrls: ['./pre-cadastro.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
    ]),
  ],
})
export class PreCadastroComponent implements OnInit {
  selected: string = 'contribuinte';
  pessoa: string = 'fisica';
  procurador: boolean = false;
  contribuinte: boolean = false;
	labels: any;
	exige : boolean = false;
  exigeCertificadoCpf: boolean = false;

  constructor(
    private router: Router,
    private cadastroService: CadastroService,
    private wizardService: WizardService,
		private translate: TranslateService
  ) {
		this.setLabels()
	}

  ngOnInit() {
    this.getPrefeituras();
    this.getConfiguracaoClienteExigeCertificado();
    this.getConfiguracaoExigeCertificadoCPF();
  }

  getPrefeituras() {
    return new Promise((resolve, reject) => {
      this.cadastroService.clientes().subscribe(
        res => {
          this.wizardService.userData.prefeituras = res.resultado;
          resolve();
        },
        error => {
          reject();
        },
      );
    });
  }

  getConfiguracaoClienteExigeCertificado(){
    return new Promise((resolve, reject) => {
      this.cadastroService.getConfiguracaoClienteExigeCertificado().subscribe(
        res => {
          this.exige = res.resultado;
          resolve();
        },
        error => {
          reject();
        },
      );
    });
  }

  getConfiguracaoExigeCertificadoCPF(){
    this.cadastroService.getConfiguracaoClienteExigeCertificadoCPF().subscribe(
      res => {
        this.exigeCertificadoCpf = res.resultado;
      },
      error => {
        console.log(error)
      });
  }

  exigeCertificado(profile: any) {
	  if(this.exige) {
      swal.fire(
        getSwal(
          'warning',
          'Exige Certificado',
          'Para fazer cadastro de Pessoa Jurídica, é exigido certificado digital padrão ICP-Brasil válido. Será exigido o certificado ao clicar em Prosseguir, e pode ser que seja redirecionado para tela de configuração de componente para leitura do certificado.',
          'Prosseguir',
          'Desistir'
        )
      ).then(res => {
        if (res.value) {
          setTimeout(() => {
            this.router.navigate(['/certificado-digital', profile]);
          }, 150);
        }else{
          setTimeout(() => {
            this.router.navigate(['/index']);
          }, 150);
        }
      });
     }
  }

  exigeCertificadoProcuradorFisico(profile){
    if(this.exigeCertificadoCpf) {
      swal.fire(
        getSwal(
          'warning',
          'Exige Certificado',
          'Para fazer cadastro de Procurador Pessoa Física, é exigido certificado digital padrão ICP-Brasil válido. Será exigido o certificado ao clicar em Prosseguir, e pode ser que seja redirecionado para a tela de configuração de componente para leitura do certificado.',
          'Prosseguir',
          'Desistir'
        )
      ).then(res => {
        if (res.value) {
          setTimeout(() => {
            this.router.navigate(['/certificado-digital', profile]);
          }, 150);
        }else{
          setTimeout(() => {
            this.router.navigate(['/index']);
          }, 150);
        }
      });
    }
  }

  redirect(profile: string) {
    if (profile === 'contribuinte') {
      this.contribuinte = true;
    } else {
      this.procurador = true;
    }
  }

  back() {
    this.procurador = false;
    this.contribuinte = false;
  }

  next(person: any, profile: any) {  
    if(localStorage.getItem('data')){
      localStorage.removeItem('data');
    }
    if(localStorage.getItem('perfil')){
      localStorage.removeItem('perfil');
    }
    if(localStorage.getItem('tipo')){
      localStorage.removeItem('tipo');
    }
    this.wizardService.userData.perfil = profile;
    this.wizardService.userData.tipo = person;

    localStorage.setItem("perfil",profile);
    localStorage.setItem("tipo",person);

    if(person === 'juridica'){
      this.exigeCertificado(profile);
    }

    if(person === 'fisica' && profile === 'procurador'){
      this.exigeCertificadoProcuradorFisico(profile);
    }

    if (this.wizardService.userData.prefeituras.length === 1) {
      this.wizardService.userData.prefeituras[0].selected = true;
      this.router.navigate(['cadastro', profile, profile === 'procurador' ? 'dados-procurador' : 'dados']);
    } else {
      this.router.navigate(['cadastro', profile, 'prefeituras']);
    }
  }

	setLabels(){
		this.labels = {
			title: this.translate.instant('PRECADASTRO.TITLE'),
			selecionarPerfil: this.translate.instant('PRECADASTRO.SELECIONARPERFIL'),
			contribuinte: this.translate.instant('PRECADASTRO.CONTRIBUINTE'),
			procurador: this.translate.instant('PRECADASTRO.PROCURADOR'),
			titleProcurador: this.translate.instant('PRECADASTRO.TITLEPROCURADOR'),
			voltar: this.translate.instant('GLOBAIS.VOLTAR'),
			prosseguir: this.translate.instant('GLOBAIS.PROSSEGUIR'),
			fisica: this.translate.instant('GLOBAIS.FISICA'),
			juridica: this.translate.instant('GLOBAIS.JURIDICA'),
		}
	}
}
