import { Component, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RegistroAcoesService } from '../../../service/registro-acoes/registro-acoes.service';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { getSwal } from '../../../utils/swalObject';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs-compat/operator/distinctUntilChanged';

@Component({
  // tslint:disable-next-line:indent
  selector: '[app-grid-registro-acoes]',
  templateUrl: './grid-registro-acoes.component.html',
  styleUrls: ['./grid-registro-acoes.component.scss']
})
export class GridRegistroAcoesComponent implements OnInit {
  @ViewChild('childFilter', {static: false}) child;

  searchForm: FormGroup;
  subscription: Subscription;
  dialogMessage = {};
  viewDialog = false;
  itemsPerView: number = 10;
  labels: any;
  loading: boolean = false;
  data = [];
  registros = [];

  constructor(
    private registroAcoesService: RegistroAcoesService,
    private translate: TranslateService
  ) {
    this.setLabels();
  }

  ngOnInit() {
    this.loading = true;
    this.subscription = this.registroAcoesService.registros$.subscribe(res => {
      this.data = res;
      this.registros = res.resultado;
      this.registros.forEach(elem => {
        elem['cpfCnpj'] = elem.usuarioCliente.usuario.cpfCnpj;
        elem['nomeRazaoSocial'] = elem.usuarioCliente.usuario.nomeRazaoSocial;
        elem['perfilAcessoDescricao'] = elem.usuarioCliente.perfilAcesso.descricao;
        elem['tipoEventoDescricao'] = elem.tipoEventoTrilha.descricao;
      }); 
      this.loading = false;
    });

    this.getRegistroAcoes();
  }

  getRegistroAcoes() {
    let params: Object = {
      pagina: 1,
      total_pagina: 10
    }
    this.registroAcoesService.getRegistrosdeAcoes(params).subscribe();
  }

  primeiraPagina() {
    this.loading = true;
    this.child.primeiraPagina();
  }

  ultimaPagina() {
    this.loading = true;
    this.child.ultimaPagina();
  }
  next() {
    this.loading = true;
    this.child.next();
  }

  previous() {
    this.loading = true;
    this.child.previous();
  }

  openDialog(item) {
    swal.fire(
      getSwal(
        'info',
        item.tipoEventoTrilha.descricao,
        item.descricao,
        'ok',
        null
      )
    );
  }

  exportarRegistros() {
    this.child.exportar();
  }

  alteraTamanhoPagina(event) {
    this.loading = true;
    let tamanhoPagina = event.target.value;
    this.itemsPerView = tamanhoPagina;
    this.child.search(1, tamanhoPagina);
  }

  alterarLoading(value) {
    this.loading = value;
  }

  setLabels() {
    this.labels = {
      title: this.translate.instant('HISTORICO.TITLE'),
      exportar: this.translate.instant('HISTORICO.EXPORTAR'),
      dataOcorrencia: this.translate.instant('HISTORICO.DATAOCORRENCIA'),
      origemIp: this.translate.instant('HISTORICO.ORIGEMIP'),
      quem: this.translate.instant('HISTORICO.QUEM'),
      tipoPerfil: this.translate.instant('HISTORICO.TIPOPERFIL'),
      tipoRegistro: this.translate.instant('HISTORICO.TIPOREGISTRO'),
      semResultado: this.translate.instant('GLOBAIS.SEMRESULTADO')
    };
  }
}
