import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs/Subscription';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FilterPipe } from '../../../../pipes/filter.pipe';
import { WizardService } from '../../../../service/wizard/wizard.service';
import { CadastroService } from '../../../../service/cadastro/cadastro.service';

@Component({
  selector: 'app-lista',
  templateUrl: 'lista.component.html',
  styleUrls: ['./lista.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
    ]),
  ],
})
export class ListaComponent implements OnInit {
  index = 1;

  routeSub: Subscription;
  perfil: string = '';

  data = {};

  title = 'Selecione uma prefeitura para vincular seu cadastro';
  searchForm: FormGroup;
  searchText = '';
  allow: boolean = false;
  loading: boolean = false;
  prefeituras = [];

  @Output() viewDialog: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private wizardService: WizardService,
    private cadastroService: CadastroService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) {}

  ngOnInit() {
    this.data = this.wizardService.userData;

    if (this.data['prefeituras'].length > 0) {
      this.prefeituras = this.data['prefeituras'];
      this.allow = true;
    } else {
      // this.loading = true;
      // this.cadastroService.clientes().subscribe(res => {
      //   this.prefeituras = res.resultado;
      //   this.loading = false;
      // }, error => {
      //   this.loading = false;
      // });
    }

    this.wizardService.updateIndex(this.index);
    this.criarFormulario();
    this.perfil = this.wizardService.userData.perfil;

    if (this.perfil === 'procurador')
      this.title =
        'Selecione abaixo as prefeituras que deseja vincular seu cadastro';
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      prefeitura: ['', Validators.required],
    });
  }

  proximo() {
    this.wizardService.userData.prefeituras = this.prefeituras;
    if (this.perfil === 'procurador')
      this.router.navigate(['cadastro/procurador/dados-procurador']);
    else this.router.navigate(['cadastro/contribuinte/dados']);
  }

  orderData(val: any) {
    if (val === 'ascendente') {
      let asc = this.prefeituras;
      asc.sort(this.compare);
    } else {
      let desc = this.prefeituras;
      desc.sort(this.compare).reverse();
    }
  }

  compare(a, b) {
    const nomeA = a.razaoSocial.toUpperCase();
    const nomeB = b.razaoSocial.toUpperCase();

    let comparison = 0;
    if (nomeA > nomeB) {
      comparison = 1;
    } else if (nomeA < nomeB) {
      comparison = -1;
    }
    return comparison;
  }

  select(prefeitura: any) {
    prefeitura.selected = true;
    if (this.perfil === 'contribuinte') {
      this.prefeituras.map(elem => {
        if (elem.razaoSocial !== prefeitura.razaoSocial)
          elem['selected'] = false;
      });
    }
    this.allow = true;
  }

  desistir() {
    this.index = this.index - 1;
    this.wizardService.updateIndex(this.index);
    this.location.back();
  }
}
