import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WizardService } from '../../../../../../service/wizard/wizard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'resumo-contribuinte-app',
  templateUrl: 'contribuinte.component.html',
  styleUrls: ['./contribuinte.component.scss'],
})
export class ResumoContribuinteComponent implements OnInit {
  routeSub: Subscription;
  perfil: string = '';
  index: number = 4;
  data = {};
  infoBasica = null;
  repLegal = null;
  procurador = null;
  prefeituras = null;
  tipo = null;
  semOutorga: boolean = false;
  termoAdesao: boolean = false
  
  @Output() onSelectTermo: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private wizardService: WizardService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.data = this.wizardService.userData;
    this.infoBasica = this.data['infoBasica'];
    this.repLegal = this.data['representanteLegal'];
    this.procurador = this.data['procurador'];
    this.prefeituras = this.data['prefeituras'];
    this.tipo = this.data['tipo'];
    this.perfil = this.data['perfil'];
    if (Object.keys(this.procurador).length !== 0) this.semOutorga = true;
    console.log('carregou terrmo:', this.termoAdesao)
  }

  edit(index, title: string) {
    this.wizardService.updateIndex(index);
    let profile = this.perfil;
    switch (title) {
      case 'prefeituras':
        this.router.navigate(['cadastro', profile, title]);
        break;
      case 'dados':
        this.perfil === 'contribuinte'
          ? this.router.navigate(['cadastro/contribuinte/dados'])
          : this.router.navigate(['cadastro/procurador/dados-procurador']);
        break;
      case 'representante':
        this.router.navigate(['cadastro', profile, title]);
        break;
      default:
        this.router.navigate(['cadastro', profile, 'resumo']);
        break;
    }
  }

  termo() {
    console.log('evento dentro:',this.termoAdesao)
    this.onSelectTermo.emit(this.termoAdesao);
  }
}
