import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { Address } from '../../model/cep.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class CepService {
  url: string = environment.BASE_URL;
  constructor(private http: HttpClient) {}

  getAddressByZipCode(zipcode: string): Observable<Address> {
    return this.http.get<Address>(`https://viacep.com.br/ws/${zipcode}/json/`);
  }

  getCep(cep: string): Observable<any> {
    return this.http.get(`${this.url}/cep/${cep}`).map(data => {
      return data;
    });
  }
}
