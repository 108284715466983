import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'searchProcuracao',
})
export class SearchProcuracaoPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    // searchText = searchText.toLowerCase();

    searchText = searchText
        .replace('.', '')
        .replace('.', '')
        .replace('-', '')
        .replace('/', '').toLowerCase();

    return items.filter(it => {
      return (
        it.outorgante.nomeRazaoSocial.toLowerCase().includes(searchText) ||
        // it.procurador.usuario.nomeRazaoSocial.toLowerCase().includes(searchText) ||
        it.outorgante.cpfCnpj.toLowerCase().includes(searchText)) ||
        ('0' + it.outorgante.inscricao).toLowerCase().includes(searchText)
        // || (it.outorgante.inscricao.toLowerCase().includes(searchText))
        
      
    });
  }
}
