import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class AmbienteService {
  url: string = environment.BASE_URL

  constructor(private http: HttpClient) { }

  obterDadosAmbiente(codigoCliente: number): Observable<any> {
    return this.http.get(`${this.url}/clientes/${codigoCliente}`)
      .pipe(map(data => {
        const body = data;
        return body
      }));
  }
}