import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: '[app-rodape]',
  templateUrl: './rodape.component.html',
  styleUrls: ['./rodape.component.scss'],
})
export class RodapeComponent {
  private data: string;
  private versao: string = environment.version;
  private dominio: string;

  constructor(
    private httpClient: HttpClient,
    @Inject(DOCUMENT) private document: Window
    ) {
      this.dominio = this.document.location.hostname;
  }
}
