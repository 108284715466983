import { Component, OnInit, Input, Output, } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {
  trigger,
  transition,
  animate,
  style,
  keyframes,
} from '@angular/animations';
import {
  FormGroup,
  FormBuilder,
  Validators,
  MinLengthValidator,
} from '@angular/forms';
import { CadastroService } from '../../../../service/cadastro/cadastro.service';
import { WizardService } from '../../../../service/wizard/wizard.service';
import { PasswordValidation } from '../../../../utils/password-validation';
import { ConsultaUsuarioService } from '../../../../service/consulta-usuario/consultar.user.service';

import swal from 'sweetalert2';
import { getSwal } from '../../../../utils/swalObject';

@Component({
  selector: 'app-validacao',
  templateUrl: 'validacao.component.html',
  styleUrls: ['./validacao.component.scss'],
  animations: [
    trigger('shake', [
      transition(
        ':enter',
        animate(
          400,
          keyframes([
            style({ transform: 'translate(30px)', offset: 0.0 }),
            style({ transform: 'translate(-30px)', offset: 0.2 }),
            style({ transform: 'translate(15px)', offset: 0.4 }),
            style({ transform: 'translate(-15px)', offset: 0.6 }),
            style({ transform: 'translate(8px)', offset: 0.8 }),
            style({ transform: 'translate(0px)', offset: 1 }),
          ]),
        ),
      ),
    ]),
  ],
})
export class ValidacaoComponent implements OnInit {
  exibirPopUp = false
  data:any = []
  passwordForm: FormGroup;
  validConfirmation = false;
  passcode = '';
  id: any = '';
  documento: any = '';
  nome: any = '';
  email: string = '';
  codigoUsuario: number;
  protocoloCodigo: number;
  definirSenha: boolean = false;
  perfil = 'Contribuinte';
  protocolo: boolean = false;
  loading: boolean = false;
  hasError: boolean = false;
  errorMsg: string = '';
  title = '';
  termo: any = {};

  constructor(
    private fb: FormBuilder,
    private wizardService: WizardService,
    private router: Router,
    private consultaUsuario: ConsultaUsuarioService,
    private cadastroService: CadastroService,
  ) {
    this.wizardService.updateIndex(6);
  }

  ngOnInit() {
    this.criarFormulario();
    this.codigoUsuario = this.wizardService.userData['codigo'];
    if(this.codigoUsuario){
      localStorage.setItem("codigo", JSON.stringify(this.codigoUsuario));
    }

    // this.codigoUsuario = 612;
    let id = this.wizardService.userData['id'];

    var res = JSON.parse(localStorage.getItem('data'))
    var res2 = JSON.parse(localStorage.getItem('codigo'))
    var res3 = JSON.parse(localStorage.getItem('userId'))

      id = res.infoBasica.cnpjContribuinte;
      this.codigoUsuario = res2;
      this.data['id'] = res.infoBasica.cnpjContribuinte;
      this.data['codigoUsuario'] = res2;
      this.data['cliente'] = res3

      if(!id){
        id = res.infoBasica.cpfCnpjProcurador;
      }

    this.consultaUsuario.consultarUsuario(id).subscribe(
      res => {
        this.formatData(res.resultado);
      },
      error => console.log(error),
    );

    this.consultaUsuario.getTermoAdesao(this.data.codigoUsuario, this.data.cliente).subscribe(
      res => {
        this.data['termo'] = res.resultado
        if(this.data['termo'].cienciaMensagem.length > 0){
          this.exibirPopUp = false
        }else{
          this.exibirPopUp = true
        }
      },
      error => console.log(error),
    );


  }

  formatData(res) {
    // this.id = res.cpfCnpj;
    this.documento = res.cpfCnpj;
    this.nome = res.nomeRazaoSocial;
    this.email = res.email;
    this.protocoloCodigo = res.codigo;
    if (this.wizardService.userData.perfil.toLowerCase() === 'procurador') {
      this.perfil = 'Procurador';
    }
    // this.title = `Acesso será concedido ao ${this.perfil} ${this.id} - ${
    //   this.nome
    // } através de:`;
  }

  criarFormulario() {
    this.passwordForm = this.fb.group(
      {
        password: [
          '',
          Validators.compose([Validators.minLength(8), Validators.required]),
        ],
        passwordConfirm: [
          '',
          Validators.compose([Validators.minLength(8), Validators.required]),
        ],
      },
      {
        validator: [PasswordValidation.MatchPassword, PasswordValidation.ValidatorPassWord]
      },
    );
  }

  infoSenha() {
    this.definirSenha = true;
  }
  confirm() {
    this.validConfirmation = true;
  }

  proximo() {
    this.loading = true;
    let senha = this.passwordForm.controls.passwordConfirm.value;
    if (this.perfil.toLowerCase() === 'contribuinte') {
      this.cadastroService
        .definirSenha(this.codigoUsuario, senha, 'definirSenhaContribuinte')
        .subscribe(
          res => this.senhaDefinida(),
          error => this.handleError(error),
        );
    } else {
      this.cadastroService
        .definirSenha(this.codigoUsuario, senha, 'definirSenhaProcurador')
        .subscribe(
          res => this.senhaDefinida(),
          error => this.handleError(error),
        );
    }
  }

  handleError(err) {
    let error = err['error'];
    this.loading = false;
    this.errorMsg = error.excecao.recomendacao;
    this.passwordForm.reset();
    swal.fire(getSwal('error', 'Ops!', err.error.excecao.recomendacao, 'ok', null));
  }

  senhaDefinida() {
    this.loading = false;
    this.definirSenha = false;
    this.protocolo = true;
  }

  login() {
    this.router.navigate(['/index']);
  }


  fecharPopUpTermoAdesao(event){
    this.exibirPopUp=false
  }

  colorPasswordList(erro){
    if(erro == true){
      return '#dc3545';
    } else {
      return '#04874b';
    }
  }
}
