import { Component } from "@angular/core";
import { CervelloService } from "../../../service/cervello/cervello.service";

@Component({
  selector: 'app-cervello',
  styleUrls: ['./chat-cervello.component.scss'],
  templateUrl: './chat-cervello.component.html'
})

export class ChatCervelloComponent {

  url: string;
  display: boolean = true;
  displayPequeno: boolean = false;

  constructor(
    private cervelloService: CervelloService
  ) { }

  public mostrarChatCervello() {
      this.cervelloService.loginPlugin(this.cervelloService.getDadosUserCervello()).subscribe( (res: any) => {
        window.open(res.resultado, "_blank", 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no,resize=no, copyhistory=no, width=1050, height=550, top='
        + (screen.height - 550) / 4 + ', left=' + (screen.width - 1050) / 2), 'true';
      });
      
    }

  toggleBotao() {
    if(this.display && !this.displayPequeno) {
      this.display = false;
      this.displayPequeno = true;
    } else {
      this.display = true;
      this.displayPequeno = false;
    }
  }
  
}
