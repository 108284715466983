import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../../shared.module';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { IndexComponent } from './index/index.component';
import { PreCadastroComponent } from './pre-cadastro/pre-cadastro.component';
import { OpcoesLoginComponent } from './opcoes-login/opcoes-login.component';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { ResetarSenhaComponent } from './resetar-senha/resetar-senha.component';
import { CertificadoComponent } from './certificado/certificado.component';
import { ConfirmacaoComponent } from './confirmacao/confirmacao.component';
import { PerfilComponent } from './perfil/perfil.component';
import { ProcuracaoComponent } from './procuracao/procuracao.component';
import { LoginRoutingModule } from './login.routing.module';
import { ECnpjComponent } from './e-cnpj/e-cnpj.component';
import { filiaisComponent } from './filiais/filiais.component';
import { IconeValidacaoComponent } from './resetar-senha/componentes/icones-validacao/icones-validacao.component';

@NgModule({
  imports: [
    LoginRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    UiSwitchModule,
    NgxMaskModule,
  ],
  exports: [],
  declarations: [
    PreCadastroComponent,
    IndexComponent,
    CertificadoComponent,
    RecuperarSenhaComponent,
    ResetarSenhaComponent,
    ConfirmacaoComponent,
    PerfilComponent,
    ProcuracaoComponent,
    ECnpjComponent,
    filiaisComponent,
    IconeValidacaoComponent
  ],
  providers: [],
})
export class LoginModule {}
