import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class ProcuracoesService {
  private procuracoes = new Subject<any>();
  procuracoes$ = this.procuracoes.asObservable();

  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  getProcuracoes(searchParams): Observable<any> {
    let params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/procuracoes`, { params: params })
      .map(data => {
        let body = data;
        this.procuracoes.next(body);
        return body;
      });
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) return params;

    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }

    for (let key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  gerarRevogacao(codigoProcuracao: string, certificado: string) {
    return this.http
      .post(
        `${this.url}/procuracoes/${codigoProcuracao}/gerarDocumentoRevogacao`,
        certificado
      )
      .map(data => {
        return data;
      });
  }

  cancelarProcuracao(codigoProcuracao, assinatura, cancelaFiliais): Observable<any> {
    console.log('cancelaFiliais params::', cancelaFiliais);
    return this.http
      .post(`${this.url}/procuracoes/${codigoProcuracao}/cancelar`, assinatura, {
        params: {filiais: cancelaFiliais}
      })
      .map(data => {
        return data;
      });
  }

  aceitarProcuracao(codigoProcuracao): Observable<any> {
    return this.http
      .get(`${this.url}/procuracoes/${codigoProcuracao}/aceitar`)
      .map(data => {
        return data;
      });
  }

  getOutorgadasAdvogados(): Observable<any> {
    return this.http
      .get(`${this.url}/procuracoes/outorgadasParaAdvogados`)
      .map(data => {
        let body = data;
        return body;
      });
  }

  getProcuracao(codigoProcuracao): Observable<any> {
    return this.http
      .get(`${this.url}/procuracoes/${codigoProcuracao}`)
      .map(data => {
        let body = data;
        return body;
      });
  }

  getRepresentanteLegal(cnpj) {
    return this.http.get(`${this.url}/procuracoes/` + cnpj + '/representanteLegal')
  }

  baixarTermoProcuracao(codigoProcuracao){
    return this.http.get(`${this.url}/procuracoes/exportarTermoProcuracao/`+codigoProcuracao,{
        responseType: "arraybuffer"
      })
      .map(data => {
        return data;
      });
  }

  salvarTermoProcuracao(termoProcuracao, codigoProcuracao){
    const fd = new FormData();
    fd.append('anexo', termoProcuracao)
    fd.append('codigoProcuracao', codigoProcuracao)
    return this.http.post(`${this.url}/procuracoes/salvarTermoProcuracao`, fd)
      .pipe(map(data => {
        return data;
    }));
  }
}
