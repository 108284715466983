import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Valida } from '../../../form-utils/valida';
import { CpfCnpjPipe } from '../../../pipes/cpf-cnpj.pipe';
import { ProcuracoesService } from '../../../service/procuracoes/procuracoes.service';
// import { DataTable } from 'ng2-data-table';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationExtras } from '@angular/router';
import { UserService } from '../../../service/user-service/user.service';

import swal from 'sweetalert2';
import { getSwal } from '../../../utils/swalObject';
import moment from 'moment-timezone';
import { DomSanitizer } from '@angular/platform-browser';
import { AmbienteService } from 'src/app/service/ambiente/ambiente.service';
import * as FileSaver from 'file-saver';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';

@Component({
  selector: '[app-grid-procuracao]',
  templateUrl: './grid-procuracao.component.html',
  styleUrls: ['./grid-procuracao.component.scss']
  // providers: [DataTable]
})
export class GridProcuracaoComponent implements OnInit {
  dialogMessage = {};
  viewDialog: boolean = false;
  errorMessage;
  selectedItem: number = 0;
  selectedEntities: any[];
  rows;
  procuracoes: Array<any> = [];
  procuracoesTable: Array<any> = [];
  data: Object = {};
  itemsPerView: number = 10;
  pagina: number;
  labels: any;
  bloquearOutorgar: boolean = false;
  dataAtual: any = moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00';
  termoProcuracao: any;
  exigeTermoProcuracao: boolean = false;
  exibirModalTermoProcuracao: boolean = false;
  termoProcuracaoOriginal: any;
  codigoCliente: any;
  codigoProcuracao;
  loading: boolean = false;
  situacaoUsuario: any;

  @ViewChild('childComponent', {static: false}) child;

  constructor(
    private procuracoesService: ProcuracoesService,
    private translate: TranslateService,
    private router: Router,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private cadastroService: CadastroService,
  ) {
    this.setLabels();
    
  }

  ngOnInit() {
    this.bloquearOutorgar = this.userService.sessionService['session'].perfilAcesso.codigo === 3;
    let usuario = JSON.parse(localStorage.getItem('session'));

    this.cadastroService.clientes().subscribe(res => {
      this.codigoCliente = res.resultado[0].codigo;
      this.termoProcuracaoOriginal = res.resultado[0].configuracao.termoProcuracao.texto;
      this.exigeTermoProcuracao = res.resultado[0].configuracao.exigeTermoProcuracao;
      if(res.resultado[0].configuracao.exigeTermoProcuracao == true){
        this.exigeTermoProcuracao = true;
      } 
    });
    this.situacaoUsuario = usuario.usuario.situacao;
  }

  getProcuracoes(params) {
    if (params.canceladas || params.aguardando) {
      params.pagina = 1;
      params.total_pagina = 100;
    }

    this.pagina = this.data['paginaAtual'];
    this.procuracoesService.getProcuracoes(params).subscribe(data => {
      this.procuracoes = data.resultado;
      this.data = data;

      this.procuracoes.map(procuracao => {
        if (procuracao.situacao.codigo === 2) {
          procuracao.situacao.descricao = 'Revogada pelo contribuinte';
        }
      });

      if (params.validas) {
        this.procuracoes = data.resultado.filter(procuracao => {
          if (procuracao.situacao.codigo === 1) {
            return procuracao;
          }
        });
      }

      if (params.canceladascontribuinte) {
        this.procuracoes = data.resultado.filter(procuracao => {
          if (procuracao.situacao.codigo === 2) {
            return procuracao;
          }
        });
      }

      if (params.aguardando) {
        this.procuracoes = data.resultado.filter(procuracao => {
          if (procuracao.situacao.codigo === 3) {
            return procuracao;
          }
        });
      }

      if (params.canceladasprocurador) {
        this.procuracoes = data.resultado.filter(procuracao => {
          if (procuracao.situacao.codigo === 4) {
            return procuracao;
          }
        });
      }


      if (params.canceladasPrazo) {
        this.procuracoes = data.resultado.filter(procuracao => {
          if (procuracao.situacao.codigo === 5) {
            return procuracao;
          }
        });
      }

      this.procuracoes.map(elem => {
        elem['cpfCnpj'] = elem.procurador.usuario.cpfCnpj;
        elem['cpfCnpjOutorgante'] = elem.outorgante.cpfCnpj;
        elem['situacaoOrder'] = elem.situacao.descricao;
        this.procuracoesTable.push(elem);
      });
    });
  }

  visualizarProcuracao(procuracao) {
    const codigo = procuracao.codigo;
    this.router.navigate([`/procuracao/outorgar/` + codigo]);
  }

  toggleView(index: any) {
    this.selectedItem = index;
  }

  cancelarProcuracao(procuracao) {
    swal.fire({
      title:'Revogar Procuração!',
      text:'Revogar a procuração para as filias também?',
      icon: 'warning',
      allowEscapeKey:false,
      allowEnterKey: false,
      allowOutsideClick:false,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showLoaderOnConfirm: true
    }).then(revogarFiliais => {
      if (revogarFiliais.value === true) {
        this.router.navigate([`/procuracao/certificados/` + procuracao.codigo + '/' + true]);
      } else {
        if(revogarFiliais.dismiss) {
          this.router.navigate([`/procuracao/certificados/` + procuracao.codigo + '/' + false]);
        }
      }  
    });
  }

  setSelectedEntities($event: any) {
    this.selectedEntities = $event;
  }

  openDialog(acao: any) {
    this.dialogMessage = acao;
    this.viewDialog = true;
  }

  next() {
    this.child.next();
  }

  previous() {
    this.child.previous();
  }

  setItemsPerView(num: number) {
    this.itemsPerView = num;
  }

  exibeTermoProcuracao(item){
    console.log(item)
    this.codigoProcuracao = item.codigo;
    let procurador = item.procurador.usuario;
    let outorgante = item.outorgante;
    let dataCurta = moment(item.inicio).format('DD/MM/YYYY');
    let dataLonga = moment(item.inicio).format('LL');

    this.termoProcuracao = this.termoProcuracaoOriginal
      .replace(/@{id do contribuinte}/g, outorgante.cpfCnpj)
      .replace(/@{contribuinte}/g, outorgante.nomeRazaoSocial)
      .replace(/@{id do procurador}/g, procurador.cpfCnpj)
      .replace(/@{procurador}/g, procurador.nomeRazaoSocial)
      .replace(/@{data curta}/g, dataCurta)
      .replace(/@{data longa}/g, dataLonga)
      .replace(/null/g, "");
      
    this.termoProcuracao = this.sanitizer.bypassSecurityTrustHtml(this.termoProcuracao);  
    this.exibirModalTermoProcuracao = true;
  }

  closeModal(){
    this.exibirModalTermoProcuracao = false;
    this.termoProcuracao = this.termoProcuracaoOriginal;
  }

  exportarPDF(){
    this.loading = true;
    this.procuracoesService.baixarTermoProcuracao(this.codigoProcuracao).subscribe(res=> {
      this.loading = false;
      var blob = new Blob([res], { type: 'application/pdf' });
      FileSaver.saveAs(blob, `Termo de Procuracao`);
    }, error => {
      this.loading = false;
      swal.fire(
        getSwal(
          'error',
          'Ops!',
          'Ocorreu um erro e não foi possível realizar a exportação, tente novamente.',
          'ok',
          null,
        ),
      );
    },)
  }

  setLabels() {
    this.labels = {
      title: this.translate.instant('PROCURACAO.TITLE'),
      botaoOutorgar: this.translate.instant('PROCURACAO.BOTAOOUTORGAR'),
      botaoCodigo: this.translate.instant('PROCURACAO.TABLECODIGO'),
      botaoProcurado: this.translate.instant('PROCURACAO.TABLEPROCURADOR'),
      botaoOutorgante: this.translate.instant('PROCURACAO.TABLEOUTORGANTE'),
      botaoDataInicio: this.translate.instant('PROCURACAO.TABLEDATAINICIO'),
      botaoDataFim: this.translate.instant('PROCURACAO.TABLEDATAFIM'),
      botaoSituacao: this.translate.instant('PROCURACAO.TABLESITUACAO'),
      botaoAbrangencia: this.translate.instant('PROCURACAO.TABLEABRANGENCIA'),
      semResultado: this.translate.instant('GLOBAIS.SEMRESULTADO'),
      cancelarProcuracao: this.translate.instant('PROCURACAO.CANCELARPROCURACAO'),
      termoProcuracao: this.translate.instant('PROCURACAO.TERMOPROCURACAO')
    };
  }
}
