import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  ViewChildren
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CaixaPostalService } from '../../../../../service/caixa-postal/caixa-postal.service';

@Component({
  selector: '[app-filter]',
  templateUrl: 'filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnChanges{
  @ViewChildren('filtros') filtros;

  searchParams: Object = {};
  filtrosAplicados: boolean = false;
  validade = null;
  public toResetFilter: boolean = false;
  public toCleanBadges: boolean = false;

  @Output() onDataChange: EventEmitter<any> = new EventEmitter<any>();
  _canVisible: number;
  filtroAtivo: any = false;
  @Input() public resetFilter: boolean;
  @Input() public cleanBadges: boolean;
  @Input()
  set canVisible(value: number) {
    this._canVisible = value;
    if (value > 0) {
      this.visible = '';
    }
  }

  get canVisible() {
    return this._canVisible;
  }

  _visible: string = '';
  @Input()
  set visible(value: string) {
    this._visible = value;
    if (this._visible === '') {
      this._canVisible = 0;
    }
  }

  onResetBadges(): void {
    this.searchParams = {};
    this.onDataChange.emit(this.searchParams);
    // this.toResetFilter = true;
  }

  get visible() {
    return this._visible;
  }

  constructor(
    private fb: FormBuilder,
    private caixaPostalService: CaixaPostalService
  ) {}

  ngOnInit() {
    localStorage.removeItem('filtroAtivo');
  }

  ngOnChanges() {
    this.toCleanBadges = this.cleanBadges;
    this.toResetFilter = this.resetFilter;
  }

  clear(params) {
    if(params == 'todos') {
      this.searchParams = {};
      this.filtroAtivo = false;
      this.filtros._results.forEach(res => {
        res.resetarParametros();
      });
      sessionStorage.removeItem('filtro');
    } else if(params == 'validade') {
      delete this.searchParams['a_vencer'];
      delete this.searchParams['vencidos'];
      delete this.searchParams['respostasVencidas'];
      delete this.searchParams['respostaAVencer'];
      delete this.searchParams['respondidas'];
    } else if (params.arquivados !== undefined) {
      delete this.searchParams['arquivados'];
    } else {
      for (let key in params) {
        delete this.searchParams[key];
      }
    }
    this.onDataChange.emit(this.searchParams);
    this.validade = this.filtros._results[1].validadeSelecionada;
    this.visible = '';
    this.verificaFiltrosAplicados();
  }

  onFilterOpen($event) {
    this.visible = $event;
  }

  cleanBadgesFilters() {
    this.searchParams = {};
    this.onDataChange.emit(this.searchParams);
  }

  tipoComunicado(dados){
    this.onDataChange.emit(dados);
    this.verificaFiltrosAplicados();
  }

  search(params) {
    for (let key in params) {
      params[key] === ''
      ? delete this.searchParams[key]
      : this.searchParams[key] = params[key];
    }

    sessionStorage.setItem('filtro', JSON.stringify(this.searchParams));
    this.onDataChange.emit(this.searchParams);
    this.validade = this.filtros._results[1].validadeSelecionada;
    this.visible = '';
    this.verificaFiltrosAplicados();
  }

  verificaFiltrosAplicados() {
    let filtro = this.searchParams;
    delete filtro['pagina'];
    delete filtro['total_pagina'];
    this.filtrosAplicados = Object.keys(filtro).length !== 0;
  }
}
