import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders  } from "@angular/common/http";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';


import { environment } from '../../../environments/environment'

@Injectable()
export class CadastroService {

  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  clientes(): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${this.url}/clientes/ativos`).map(data => { return data }).catch(this.handleError);
  }

  cadastrarContribuinte(data: any): Observable<any> {
    let autocadastro = data;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${this.url}/cadastrarContribuinte`, autocadastro)
  }

  cadastrarProcurador(data: any): Observable<any> {
    let autocadastro = data;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${this.url}/cadastrarProcurador`, autocadastro)
      
  }


  validarUsuario(codigoUsuario, codigoValidacao, endpoint): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams({
      fromObject: {
        codigoUsuario: codigoUsuario,
        codigoVerificacao: codigoValidacao
      }
    });
    return this.http.get(`${this.url}/${endpoint}`, {params: params}).map(data => { return data })
  }

  definirSenha(codigoUsuario, senha, endpoint): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams({
      fromObject: {
        codigoUsuario: codigoUsuario,
        senha: senha
      }
    });
    return this.http.get(`${this.url}/${endpoint}`, {params: params}).map(data => { return data })
  }

  private handleError(error: Response | any) {
    console.log(error.message || error);
    return Observable.throw(error.message || error);
  }

  getConfiguracaoClienteExigeCertificado(): Observable<any> {
    return this.http.get(`${this.url}/exigeautocadastro`).map(data => { return data })
  }

  getConfiguracaoClienteExigeCertificadoCNPJ(): Observable<any> {
    return this.http.get(`${this.url}/exigecertificadocnpj`).map(data => { return data })
  }

  getConfiguracaoClienteExigeCertificadoCPF(): Observable<any> {
    return this.http.get(`${this.url}/exigecertificadocpf`).map(data => { return data })
  }
  
  getEstadoCivil(): Observable<any> {
    return this.http.get(`${this.url}/estadoCivil`).map(data => {
      return data;
    });
  }

}
