import { Directive, ElementRef, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
	selector: '[data]',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: DataDirective,
		multi: true
	}]
})
export class DataDirective implements ControlValueAccessor {

	// private onChange: any;
	private onTouched: any;
	
	constructor(private elementRef: ElementRef) {
	
	}
	
	writeValue(value: any) {
		this.elementRef.nativeElement.value = value;
	}
	
	registerOnChange(fn: any) {
		this.onChange = fn;
	}
	
	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}
	
	onChange(key) {
		console.log('key', key);
	}

	@HostListener('keyup', ['$event'])
	onKeyup(event: any) {
		var input = event.target.value.replace(/\D/g,'');
		var valor = '';
		
		for(var i = 0; i < input.length; i++) {
			if(i == 2 || i == 4) {
				valor += '/';
			}
			
			valor += input.charAt(i);
		}
		
		this.onChange(valor);
		event.target.value = valor;
	}
	
	@HostListener('blur', ['$event'])
	onBlur(event: any) {
		this.onTouched();
	}
}