import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AutoCadastroComponent } from './auto-cadastro.component';
import { ValidacaoComponent } from './pages/validacao/validacao.component';
import { ResumoComponent } from './pages/resumo/resumo.component';
import { RepresentanteLegalComponent } from './pages/representante-legal/representante-legal.component';
import { ListaComponent } from './pages/lista/lista.component';
import { DadosBasicosComponent } from './pages/dados-basicos/dados-basicos.component';
import { DadosProcuradorComponent } from './pages/dados-procurador/dados-procurador.component';
import { CodigoComponent } from './pages/codigo/codigo.component';
import { CertificadoComponent } from './pages/certificado/certificado.component';


const routes: Routes = [
		{ path: 'cadastro/:perfil', component: AutoCadastroComponent, children: [
		{ path: '', redirectTo: 'prefeituras', pathMatch: 'full' },
		{ path: 'prefeituras', component: ListaComponent},
		{ path: 'dados', component: DadosBasicosComponent},
		{ path: 'dados-procurador', component: DadosProcuradorComponent},
		{ path: 'representante', component: RepresentanteLegalComponent},
    { path: 'resumo', component: ResumoComponent},
    { path: 'resumo-atribuir-contribuinte', component: ResumoComponent},
		{ path: 'codigo', component: CodigoComponent},
		{ path: 'codigo-fisica', component: CodigoComponent},
		{ path: 'senha', component: ValidacaoComponent},
		{ path: 'certificado/:codigoUsuario/:codigoCliente', component: CertificadoComponent}
	] }
];


@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class CadastroRoutingModule { 

}
