import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { Router } from '@angular/router';
import { UserService } from '../../../service/user-service/user.service';
import { SessionService } from '../../../service/session/session.service';
import { GlobalVariable } from '../../../model/global-variable';

@Component({
  selector: 'app-index',
	templateUrl: './index.component.html',
	styleUrls: ['./index.component.scss'],
	animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('500ms ease-in', style({transform: 'translateX(0%)'}))
      ])
    ])
  ]
})
export class IndexComponent implements OnInit {
	active = 0;
	view:string = 'index';
	

	constructor(private router: Router, private sessionService: SessionService, private userService: UserService) {}
 
	ngOnInit() {
		if(this.sessionService.getSession()) {
      this.router.navigate(['/caixa-postal']);
    }
	}
}