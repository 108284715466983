import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LacunaWebPKI, CertificateModel, ExceptionModel } from 'web-pki';
import { CertificadoService } from '../../../../service/certificado/certificado.service';
import { ProcuracoesService } from '../../../../service/procuracoes/procuracoes.service';
import { ConsultaUsuarioService } from '../../../../service/consulta-usuario/consultar.user.service';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs/Subject';
import { Location } from '@angular/common';

import swal from 'sweetalert2';

import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { getSwal } from '../../../../utils/swalObject';

@Component({
  selector: 'app-certificado',
  templateUrl: './certificado.component.html',
  styleUrls: ['./certificado.component.scss']
})
export class CertificadoComponent implements OnInit {

  private pki: LacunaWebPKI;
  searchResults: Array<any> = [];
  buscando = false;
  serverData: Object = {};
  disabled = false;
  loading = false;
  today: Date = new Date();
  outorgarData: any = null;
  chamada:any = [];
  certificado: any = null;
  certEncoded: any;
  labels: any;
  codigoCliente: any;
  codigoUsuario: any;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private procuracoesService: ProcuracoesService,
    private config: CertificadoService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private consultaUsuarioService: ConsultaUsuarioService,
    private _location: Location
  ) {
    this.pki = new LacunaWebPKI(config.webPkiLicense);
    this.setLabels();
  }

  ngOnInit() {
    this.buscando = true;
    this.activatedRoute.params.subscribe(params => {
      this.codigoUsuario = params['codigoUsuario'];
      this.codigoCliente = params['codigoCliente'];
      this.pki.init({
        ngZone: this.ngZone,
        ready: this.onWebPkiReady,
        notInstalled: this.onWebPkiNotInstalled
      });
    });
  }

  private onWebPkiReady = () => {
    this.carregarCertificados();
  };

  carregarCertificados() {
    this.buscando = true;
    this.pki.listCertificates({}).success(certs => {
      this.buscando = false;
      this.searchResults = certs;
      this.buscando = false;
    });
  }

  update() {
    this.carregarCertificados();
  }

  private onWebPkiNotInstalled = (status, message) => {
    this.buscando = false;
    this.pki.redirectToInstallPage();
  };

  onSelect(e, certificado) {
    this.loading = true;
    this.certificado = certificado;
    this.disabled = !this.disabled;
    this.pki.readCertificate(certificado.thumbprint).success(certEncoded => {
      this.certEncoded = certEncoded;
      if (this.codigoUsuario) {
        this.chamada['certificado'] = certEncoded
        this.chamada['codigoUsuario'] = this.codigoUsuario
        this.chamada['codigoCliente'] = this.codigoCliente
        // this.outorgarData['certificado'] = certEncoded;
        // this.outorgarProcuracaoService.setData(this.outorgarData);
      }

      this.handleDocument();
    });
  }

  handleDocument() {
    if (this.codigoUsuario) {
      this.consultaUsuarioService
        .gerarDocumento(this.chamada)
        .subscribe(
          res => {
            this.signDocument(res.resultado);
          },

          error => {
            swal.fire(
              getSwal(
                'error',
                'Ops! Ocorreu um erro',
                'Ocorreu um erro em autenticação, tente novamente',
                'ok',
                null
              )
            ).then(res => {
              this._location.back();
            });
          }
        );
        return
    }
  }

  signDocument(response) {
    this.outorgarData = response;
    const thumbprint = this.certificado.thumbprint;
    const hash = response.toSignHash;
    const digestAlgorithm = response.digestAlgorithm;
    
    this.pki
      .signHash({
        thumbprint: thumbprint,
        hash: hash,
        digestAlgorithm: digestAlgorithm
      })
      .success(signature => {
        // return
        const docSign = response;
        docSign.assinatura = signature;
        // if (this.id) {
          this.consultaUsuarioService
            .darCiencia(this.codigoCliente, this.codigoUsuario, docSign)
            .subscribe(
              res => {
                swal.fire(
                  getSwal(
                    'success',
                    'Sucesso!',
                    'Assinatura digital foi efetuada com êxito.',
                    'ok',
                    null
                  )
                ).then(res => {
                  this._location.back();
                });
              },
              error => {
                swal.fire(
                  getSwal(
                    'error',
                    'Erro',
                    'Ocorreu um erro na assiantura digital não pôde ser efetuada.',
                    'ok',
                    null
                  )
                );
              }
            );
        // }

        // var blob = new Blob([response], { type: 'application/pdf' });
        // FileSaver.saveAs(blob, `dossie`);
      });
  }

  handleResponse(res) {
    this.loading = false;
  }

  handleError(err) {
    const error = err['error'];
    this.loading = false;
  }

  setLabels() {
    this.labels = {
      title: this.translate.instant('PROCURACAO.TITLEOUTORGAR'),
      atualizar: this.translate.instant('GLOBAIS.ATUALIZAR'),
      cpfCnpj: this.translate.instant('GLOBAIS.CPFCNPJ'),
      nomeRazaoSocial: this.translate.instant('GLOBAIS.NOMERAZAOSOCIAL'),
      email: this.translate.instant('GLOBAIS.EMAIL'),
      celular: this.translate.instant('GLOBAIS.CELULAR'),
      cadastrar: this.translate.instant('GLOBAIS.CADASTRAR'),
      dataInicial: this.translate.instant('GLOBAIS.DATAINICIAL'),
      dataFinal: this.translate.instant('GLOBAIS.DATAFINAL'),
      papel: this.translate.instant('GLOBAIS.PAPEL'),
      selecione: this.translate.instant('GLOBAIS.SELECIONE'),
      abrangencias: this.translate.instant('GLOBAIS.ABRANGENCIAS'),
      abrangenciasText: this.translate.instant('PROCURACAO.ABRANGENCIASTEXT'),
      proximo: this.translate.instant('GLOBAIS.PROXIMO'),
      desistir: this.translate.instant('GLOBAIS.DESISTIR'),
      cadastrarProcurador: this.translate.instant('PROCURACAO.CADASTRARPROCURADOR'),
      buscarCertificados: this.translate.instant('PROCURACAO.BUSCANDOCERTIFICADOS'),
      certificadosEncontrados: this.translate.instant('PROCURACAO.CERTIFICADOSENCONTRADOS'),
      selecioneCertificado: this.translate.instant('PROCURACAO.SELECIONARCERTIFICADO'),
      validoDe: this.translate.instant('PROCURACAO.VALIDODE'),
      validoAte: this.translate.instant('PROCURACAO.VALIDOATE'),
      vencido: this.translate.instant('PROCURACAO.VENCIDO')
    };
  }
}
