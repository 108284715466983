import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


import { UserService } from '../user-service/user.service';

import { Router } from '@angular/router';
import { SessionService } from '../session/session.service';

@Injectable()

export class HttpsRequestInterceptor implements HttpInterceptor {

  constructor(public router: Router, private sessionService: SessionService, private userService: UserService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let token = this.sessionService.getToken();
    let headers = new HttpHeaders();

    if (typeof token !=='undefined' && token) {
      headers = headers.append("Authorization", token).append('Cache-Control', 'no-cache').append('Pragma', 'no-cache'); 
    }
    
    const dupReq = req.clone({ headers });
    return next.handle(dupReq).catch((err: HttpErrorResponse) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err.status === 0 ) {
          window.localStorage.setItem("sentToSignIn", 'true');
          this.userService.sendInfo({ loggedIn: false });
          this.sessionService.clearSession();
          // this.router.navigate(['/'])
        };
      }
      return Observable.throw(err);
    });


  }
}



