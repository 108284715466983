import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { HttpClientModule } from '@angular/common/http';
import { RegistroAcoesRoutingModule } from './registro-acoes.routing.module';
import { DataTableModule } from "ng2-data-table";
import { DpDatePickerModule } from 'ng2-date-picker';
import { CommonAppModule } from '../../features/common/common.module'
import { RegistroAcoesComponent } from './registro-acoes/registro-acoes.component'
import { GridRegistroAcoesComponent } from './grid-registro-acoes/grid-registro-acoes.component'
import { FilterComponent } from './grid-registro-acoes/components/filter.component'



@NgModule({
  imports: 
  [
    CommonAppModule,
    CommonModule, 
    DataTableModule,
    DpDatePickerModule, 
    FormsModule, 
    HttpClientModule,
    ReactiveFormsModule,  
    RegistroAcoesRoutingModule,
    SharedModule
  ],
  exports: [GridRegistroAcoesComponent, RegistroAcoesComponent],
  declarations: [GridRegistroAcoesComponent, RegistroAcoesComponent, FilterComponent],
  providers: [],
})
export class RegistroAcoesModule { }
