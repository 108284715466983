import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as FileSaver from 'file-saver';
import moment from 'moment-timezone';
import { SessionService } from './../../../../service/session/session.service';
import { RegistroAcoesService } from '../../../../service/registro-acoes/registro-acoes.service';
import { invalid } from 'moment';

@Component({
  selector: 'app-filter',
  templateUrl: 'filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Output() onDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Input('itemsPerView') itemsPerView;
  @Input('loading') loading;

  searchForm: FormGroup;
  visible = '';
  inicio;
  doc: string = '';
  dataFiltro = null;
  dataInicialValida = false;
  dataFinalValida = false;
  data: Object = {};
  searchParams: Object = {
    pagina: 1,
    total_pagina: 10
  };
  dates: any = {
    data_final: null,
    data_inicial: null
  };
  perfisAcesso: Array<any> = [];
  tiposRegistro: Array<any> = [];
  tipoRegistro = 'Tipo de Registro';
  perfilAcesso = 'Perfil';
  tipo = 'Tipo';
  tipoSelecionado = '';
  perfilSelecionado = '';
  registroSelecionado = '';
  dataInclusaoValida: boolean = false;
  dataFimInclusaoValida: boolean = false;
  periodo: boolean = false;
  thisNow;
  configuracaoData = {
    format: 'YYYY-DD-MM'
  };
  userInfo: any;
  cont = 1;
  filtrosAplicados: boolean = false;

  constructor(
    private fb: FormBuilder,
    private registroAcoesService: RegistroAcoesService,
    private sessionService: SessionService
  ) {
    this.criarFormulario();
    this.userInfo = this.sessionService.getSession();
  }

  ngOnInit() {
    this.thisNow = moment(new Date()).format('YYYY-DD-MM');

    this.registroAcoesService.getData().subscribe(
      res => {
        this.handleResponse(res);
      },
      error => console.log(error)
    );
  }

  handleResponse(response) {
    this.perfisAcesso = response[0].resultado;
    this.tiposRegistro = response[1].resultado;
    this.perfisAcesso.forEach(elem => (elem['checked'] = false));
    this.tiposRegistro.forEach(elem => (elem['checked'] = false));
    this.pesquisarOcorrencia(true);
  }

  primeiraPagina() {
    this.searchParams['pagina'] = 1;
    this.search(0);
  }

  ultimaPagina() {
    this.searchParams['pagina'] = this.searchParams['totalPaginas'];
    this.search(0);
  }

  next() {
    if (!this.data['ultimaPagina']) {
      this.searchParams['pagina']++;
      this.search(0);
    }
  }

  previous() {
    if (!this.data['primeiraPagina']) {
      this.searchParams['pagina']--;
      this.search(0);
    }
  }

  exportar() {
    this.onDataChange.emit(true);
    this.visible = '';
    const params = {};
    for(let key in this.searchParams) {
      if(key !== 'total_pagina' && key !== 'pagina') {
        params[key] = this.searchParams[key];
      }
    }

    this.registroAcoesService
      .exportarRegistros(params)
      .subscribe(res => {
        const blob = new Blob([res], { type: 'application/pdf' });
        FileSaver.saveAs(blob, `registros${new Date(Date.now())}`);
        this.onDataChange.emit(false);
      }, error => {
        this.onDataChange.emit(false);
      });
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      assunto: ''
    });
  }

  selecionarDataInicial(event) {
    const dataSelecionada = moment(event, 'DD-MM-YYYY');
    const dataAtual = moment();

    if (dataSelecionada.isAfter(dataAtual)) {
      this.dataInicialValida = false;
      return;
    } if ((this.dates['data_inicial'].isAfter(dataAtual))) {
      this.dataInicialValida = false;
    } else {
      this.dataInicialValida = true;
    }

    if (this.dates['data_inicial'] > this.dates['data_final']) {
      setTimeout(() => {
        this.dates['data_final'] = null;
      }, 100);
    }
  }

  selecionarDataFinal(event) {
    const dataSelecionada = moment(event, 'DD-MM-YYYY');
    const dataAtual = moment();

    if (dataSelecionada.isAfter(dataAtual)) {
      this.dataFimInclusaoValida = false;
      return;
    } if ((this.dates['data_inicial'].isAfter(dataAtual))) {
      this.dataFimInclusaoValida = false;
    } else {
    } if ((this.dates['data_final'].isAfter(dataAtual))) {
      this.dataFimInclusaoValida = false;
    } else {
      this.dataFimInclusaoValida = true;
    }
  }

  pesquisarOcorrencia(search: boolean) {
    if (!search) {
      this.visible = '';
      this.dataFiltro = null;
      this.dates = {
        data_final: null,
        data_inicial: null
      };
      this.dataInclusaoValida = false;
      this.dataFimInclusaoValida = false;
      delete this.searchParams['data_inicial'];
      delete this.searchParams['data_final'];
    } else {
      if (this.dates['data_inicial'] != null) {
        this.searchParams['data_inicial'] = moment(
          this.dates['data_inicial'].valueOf()
        ).format('YYYY-MM-DD');
        this.dataFiltro = moment(this.dates['data_inicial'].valueOf()).format(
          'DD/MM'
        );
      }

      if (this.dates['data_final'] != null) {
        this.searchParams['data_final'] = moment(
          this.dates['data_final'].valueOf()
        ).format('YYYY-MM-DD');
        this.dataFiltro =
          this.dataFiltro +
          ' - ' +
          moment(this.dates['data_final'].valueOf()).format('DD/MM');
      }
    }
    this.search(1);
    this.visible = '';
  }

  toggleVisibility(item: string) {
    if (item === this.visible) {
      this.visible = '';
    } else {
      this.visible = item;
    }
  }

  alteracaoFilter(index: number) {
    this.tipoRegistro = this.tiposRegistro[index].descricao;
    this.searchParams['codigo_tipo_registro'] = this.tiposRegistro[
      index
    ].codigo;
  }

  perfilFilter(index: number) {
    this.perfilAcesso = this.perfisAcesso[index].descricao;
    this.searchParams['codigo_perfil'] = this.perfisAcesso[index].codigo;
  }

  tipoFilter(index: number) {
    this.tipo = index == 0 ? 'Próprias' : 'De Procuradores';
    this.searchParams['tipo'] = index;
  }

  resetarFiltros(filter: string) {
    if (filter === 'registro') {
      this.tipoRegistro = 'Tipo de Registro';
      this.registroSelecionado = '';
      delete this.searchParams['codigo_tipo_registro'];
    } else if (filter === 'tipo') {
      this.tipo = 'Tipo';
      this.tipoSelecionado = '';
      delete this.searchParams['tipo'];
    } else {
      this.perfilAcesso = 'Perfil';
      this.perfilSelecionado = '';
      delete this.searchParams['codigo_perfil'];
    }
    this.search(1);
    this.visible = '';
  }

  limparTodosFiltros() {
    this.periodo = false;
    this.dataInclusaoValida = false;
    this.dataFimInclusaoValida = false;
    this.onDataChange.emit(true);
    this.visible = '';
    this.tipoRegistro = 'Tipo de Registro';
    this.registroSelecionado = '';
    this.perfilAcesso = 'Perfil';
    this.perfilSelecionado = '';
    this.tipo = 'Tipo';
    this.tipoSelecionado = '';
    this.dataFiltro = null;
    this.dates = {
      data_inicial: null,
      data_final: null
    }
    this.searchParams = {
      pagina: 1,
      total_pagina: this.searchParams['total_pagina']
    };
    this.filtrosAplicados = false;
    this.registroAcoesService.getRegistrosdeAcoes(this.searchParams).subscribe();
  }

  search(number: number, tamanhoPagina?: number) {
    this.onDataChange.emit(true);
    if(number === 1){
      this.searchParams['pagina'] = 1;
      this.data['paginaAtual'] = 1;
    }

    if(tamanhoPagina !== undefined) {
      this.searchParams['total_pagina'] = tamanhoPagina;
    }

    this.itemsPerView = this.searchParams['total_pagina'];
    this.visible = '';

    this.registroAcoesService.getRegistrosdeAcoes(this.searchParams).subscribe(res => {
      this.searchParams['totalPaginas'] = res.totalPaginas;
    });
    this.verificaFiltrosAplicados(this.searchParams);
  }

  verificaFiltrosAplicados(params) {
    let encontrouFiltro: boolean = false;
    for(let key in params) {
      if(key !== 'total_pagina' && key !== 'pagina') {
        encontrouFiltro = true;
      }
    }
    encontrouFiltro ? this.filtrosAplicados = true : this.filtrosAplicados = false;
  }
}
