import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Router } from '@angular/router';

import { IndexComponent } from './index/index.component';
import { PreCadastroComponent } from './pre-cadastro/pre-cadastro.component';
import { OpcoesLoginComponent } from './opcoes-login/opcoes-login.component';
import { CertificadoComponent } from './certificado/certificado.component';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { ResetarSenhaComponent } from './resetar-senha/resetar-senha.component';
import { ConfirmacaoComponent } from './confirmacao/confirmacao.component';
import { PerfilComponent } from './perfil/perfil.component';
import { ProcuracaoComponent } from './procuracao/procuracao.component';
import { ECnpjComponent } from './e-cnpj/e-cnpj.component';
import { filiaisComponent } from './filiais/filiais.component'


const routes: Routes = [
	{ path: 'sign', redirectTo: 'home', pathMatch: 'full' },
	{ path: 'pre-cadastro', data: {modulo: '0'}, component: PreCadastroComponent},
	{ path: 'entrar', redirectTo: 'home', pathMatch: 'full' },
	{ path: 'perfis', data: {modulo: '0'}, component: PerfilComponent},
	{ path: 'empresas', component: ECnpjComponent},
	{ path: 'filiais', component: filiaisComponent},
	{ path: 'procuracoes', data: {modulo: '0'}, component: ProcuracaoComponent},
	{ path: 'certificado', data: {modulo: '0'}, component: CertificadoComponent },
	{ path: 'certificado-digital/:tipo', data: {modulo: '0'}, component: CertificadoComponent },
	{ path: 'recuperar-senha', data: {modulo: '0'}, component: RecuperarSenhaComponent },
	{ path: 'alterar-senha/:token', data: {modulo: '0'}, component: ResetarSenhaComponent },
	{ path: 'confirmar', data: {modulo: '0'}, component: ConfirmacaoComponent }
];


@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class LoginRoutingModule {}
