import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
	selector: '[cpfCnpj]',
	providers: [{
    	provide: NG_VALUE_ACCESSOR, 
    	useExisting: CpfCnpjDirective, 
    	multi: true 
  	}]
})
export class CpfCnpjDirective implements ControlValueAccessor {

	@Input("cpfCnpj") tipo: string;

	// private onCkhange: any;
  	private onTouched: any;
  	
  	constructor(private elementRef: ElementRef) {
  	
  	}
 
  	writeValue(value: any) {
  		this.elementRef.nativeElement.value = value;
  	}
 
  	registerOnChange(fn: any) {
    	this.onChange = fn;
  	}
 
  	registerOnTouched(fn: any) {
    	this.onTouched = fn;
	  }
	  
	  onChange(key) {
		console.log('key', key);
	  }

	@HostListener('keyup', ['$event'])
	onKeyup(event: any) {
		var input = event.target.value.replace(/\D/g,'');
		var valor = '';

		if(this.tipo) {	
			if(this.tipo.toUpperCase() == "CPF") {
				for(var i = 0; i < input.length && i < 11; i++) {
					if(i == 3 || i == 6) {
						valor += '.';
					} else if(i == 9) {
						valor += '-';
					}
					valor += input.charAt(i);
				}
			} else if(this.tipo.toUpperCase() == "CNPJ") {
				for(var i = 0; i < input.length && i < 14; i++) {
					if(i == 2 || i == 5) {
						valor += '.';
					} else if(i == 8) {
						valor += '/';
					} else if(i == 12) {
						valor += '-';
					}
					valor += input.charAt(i);
				}
			} else {
				valor = event.target.value;
			}
		} else {
			for(var i = 0; i < input.length && i < 14; i++) {
				if(input.length <= 11) { 
					if(i == 3 || i == 6) {
						valor += '.';
					} else if(i == 9) {
						valor += '-';
					}
				} else {
					if(i == 2 || i == 5) {
						valor += '.';
					} else if(i == 8) {
						valor += '/';
					} else if(i == 12) {
						valor += '-';
					}
				}
				valor += input.charAt(i);
			}
		}

		this.onChange(valor);
		event.target.value = valor;	
	}
	
	@HostListener('blur', ['$event'])
	onBlur(event: any) {
		this.onTouched();
	}
}