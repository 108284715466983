import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';
import { Subscription, BehaviorSubject } from 'rxjs';

@Injectable()
export class ContribuinteService {
  url: string = environment.BASE_URL;
  contribuintes = new Subject<any>();
  contribuintes$ = this.contribuintes.asObservable();

  contribuinte = new BehaviorSubject<any>({});
  contribuinte$ = this.contribuinte.asObservable();

  contabilista = new BehaviorSubject<any>({});
  contabilista$ = this.contabilista.asObservable();

  dados = {};
  constructor(private http: HttpClient) {}

  obterMockContribuintes(): any {
    this.contribuinte.next(this.getContribuinteMock());
    return this.contribuinte;
  }

  getContribuinteMock() {
    return {
      razaoSocial: 'GRENDENE S A',
      inscricao: '069161135',
      cpfCnpj: '89850341000160',
      nomeFantasia: 'GRENDENE S/A',
      regimeRecolhimento: 'NORMAL',
      inicioAtividade: '1993-05-27T21:00:00',
      situacaoCadastral: 'ATIVO',
      telefone: '31122999',
      email: 'strada@grendene.com.br',
      municipio: 'SOBRAL',
      uf: 'CE',
      quadroSocietario: [
        {
          nome: 'PEDRO GRENDENE BARTELLE',
          cpf: '9864784072',
          cargo: 'DIRETOR',
          participacao: 0.0,
          dataInicio: '2093-05-27T21:00:00',
          dataFim: '2013-06-06T21:00:00',
          email: null,
          participaOutrasEmpresas: true,
        },
        {
          nome: 'GELSON LUIZ ROSTIROLLA',
          cpf: '14841142991',
          cargo: 'DIRETOR',
          participacao: 0.0,
          dataInicio: '2011-11-07T21:00:00',
          dataFim: null,
          email: null,
          participaOutrasEmpresas: true,
        },
        {
          nome: 'NELSA NAVILHA GRENDENE BARTELLE',
          cpf: '32850549053',
          cargo: 'DIRETOR',
          participacao: 0.0,
          dataInicio: '0093-05-27T21:00:00',
          dataFim: null,
          email: null,
          participaOutrasEmpresas: true,
        },
        {
          nome: 'FRANCISCO OLINTO VELO SCHMITT',
          cpf: '26363798000',
          cargo: 'DIRETOR',
          participacao: 0.0,
          dataInicio: '2011-11-07T21:00:00',
          dataFim: null,
          email: null,
          participaOutrasEmpresas: true,
        },
        {
          nome: 'ALEXANDRE GRENDENE BARTELLE',
          cpf: '9867597087',
          cargo: 'PRESIDENTE',
          participacao: 0.0,
          dataInicio: '2093-05-27T21:00:00',
          dataFim: '2013-06-06T21:00:00',
          email: null,
          participaOutrasEmpresas: true,
        },
        {
          nome: 'RUDIMAR DALL ONDER',
          cpf: '25462687087',
          cargo: 'PRESIDENTE',
          participacao: 0.0,
          dataInicio: '2013-06-06T21:00:00',
          dataFim: null,
          email: null,
          participaOutrasEmpresas: true,
        },
        {
          nome: 'VERONA NEGOCIOS E PARTICIPACOES S/A',
          cpf: '60096153000106',
          cargo: 'SOCIO',
          participacao: 0.24,
          dataInicio: '2008-04-14T21:00:00',
          dataFim: '2011-11-07T21:00:00',
          email: null,
          participaOutrasEmpresas: true,
        },
        {
          nome: 'GRENDENE NEGOCIOS S/A',
          cpf: '4819807000101',
          cargo: 'SOCIO',
          participacao: 0.2,
          dataInicio: '2008-04-14T21:00:00',
          dataFim: '2011-11-07T21:00:00',
          email: null,
          participaOutrasEmpresas: true,
        },
        {
          nome: 'ALEXANDRE GRENDENE B PARTICIP. S/A',
          cpf: '4819746000182',
          cargo: 'SOCIO',
          participacao: 0.3,
          dataInicio: '2008-04-14T21:00:00',
          dataFim: '2011-11-07T21:00:00',
          email: null,
          participaOutrasEmpresas: true,
        },
        {
          nome: 'GRENDENE SÖA',
          cpf: '89850341000160',
          cargo: 'SOCIO',
          participacao: 98.0,
          dataInicio: '0093-05-27T21:00:00',
          dataFim: null,
          email: null,
          participaOutrasEmpresas: true,
        },
      ],
      contabilistas: [
        {
          celular: 'string',
          codigo: 0,
          cpfCnpj: 'string',
          crc: 'string',
          editavel: true,
          email: 'string',
          fimVinculo: '2018-12-28T15:54:11.897Z',
          inicioVinculo: '2018-12-28T15:54:11.897Z',
          nomeRazaoSocial: 'string',
          quantidadeColaboradores: 0,
          telefone: 'string',
        },
        {
          celular: 'string',
          codigo: 0,
          cpfCnpj: 'string',
          crc: 'string',
          editavel: true,
          email: 'string',
          fimVinculo: '2018-12-28T15:54:11.897Z',
          inicioVinculo: '2018-12-28T15:54:11.897Z',
          nomeRazaoSocial: 'string',
          quantidadeColaboradores: 0,
          telefone: 'string',
        },
        {
          celular: 'string',
          codigo: 0,
          cpfCnpj: 'string',
          crc: 'string',
          editavel: true,
          email: 'string',
          fimVinculo: '2018-12-28T15:54:11.897Z',
          inicioVinculo: '2018-12-28T15:54:11.897Z',
          nomeRazaoSocial: 'string',
          quantidadeColaboradores: 0,
          telefone: 'string',
        },
      ],
    };
  }

  obterContribuintes(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/contribuintes`, { params: params })
      .map(data => {
        const body = data;
        this.contribuintes.next(body);
        return body;
      });
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  getData(): Observable<any> {
    const sistemas = this.http
      .get(`${this.url}/aplicacoes/funcionalidadesAutorizadasProcuracao`)
      .map(res => res);
    const tiposProcuracao = this.http
      .get(`${this.url}/tiposProcuracao/ativos`)
      .map(res => res);
    return Observable.forkJoin([sistemas, tiposProcuracao]);
  }

  buscarProcurador(cpfCnpj): Observable<any> {
    const params = new HttpParams().set('cpfCnpj', cpfCnpj);
    return this.http
      .get(`${this.url}/usuarios/procuradores/ativos`, { params: params })
      .map(data => {
        return data;
      });
  }

  setContribuinte(contribuinte) {
    this.contribuinte.next(contribuinte);
  }

  setContabilista(contabilista) {
    this.contabilista.next(contabilista);
  }

  consultarFiliadosContador(
    cpfCnpjContribuinte,
    codigoContabilista,
  ): Observable<any> {
    return this.http
      .get(
        `${
          this.url
        }/contribuintes/${cpfCnpjContribuinte}/contabilistas/${codigoContabilista}/filiados`,
      )
      .map(data => {
        const body = data;
        return body;
      });
  }

  adicionarFiliado(
    cpfCnpjContribuinte,
    codigoContabilista,
    procuracao,
  ): Observable<any> {
    return this.http
      .post(
        `${
          this.url
        }/contribuintes/${cpfCnpjContribuinte}/contabilistas/${codigoContabilista}/filiados`,
        procuracao,
      )
      .map(data => {
        const body = data;
        return body;
      });
  }

  consultarFiliadoContador(
    cpfCnpjContribuinte,
    codigoContabilista,
    codigo,
  ): Observable<any> {
    return this.http
      .get(
        `${
          this.url
        }/contribuintes/${cpfCnpjContribuinte}/contabilistas/${codigoContabilista}/filiados/${codigo}`,
      )
      .map(data => {
        const body = data;
        return body;
      });
  }

  editarFiliado(
    cpfCnpjContribuinte,
    codigoContabilista,
    codigo,
    procuracao,
  ): Observable<any> {
    return this.http
      .put(
        `${
          this.url
        }/contribuintes/${cpfCnpjContribuinte}/contabilistas/${codigoContabilista}/filiados/${codigo}`,
        procuracao,
      )
      .map(data => {
        const body = data;
        return body;
      });
  }

  cancelarFiliacao(
    cpfCnpjContribuinte,
    codigoContabilista,
    codigo,
  ): Observable<any> {
    return this.http
      .get(
        `${
          this.url
        }/contribuintes/${cpfCnpjContribuinte}/contabilistas/${codigoContabilista}/filiados/${codigo}/cancelar`,
      )
      .map(data => {
        const body = data;
        return body;
      });
  }
  
}
