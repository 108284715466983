import { CaixaPostalService } from './../../../../../../service/caixa-postal/caixa-postal.service';
import { TiposComunicadoService } from './../../../../../../service/tipos-comunicado/tipos-comunicado.service';
import { Component, OnInit, Output, Input, EventEmitter, OnChanges, AfterViewChecked } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tipo-comunicado',
  templateUrl: 'tipo-comunicado.component.html',
  styleUrls: ['./tipo-comunicado.component.scss']
})
export class TipoComunicadoComponent implements OnInit, AfterViewChecked{
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageUpdate: EventEmitter<number> = new EventEmitter<number>();

  @Input() filtroAtivo: any;

  tiposComunicado: Array<any> = [];
  searchForm: FormGroup;
  comunicados = [];
  comunicado = 'Tipo Comunicado';
  comunicadoSelecionado: any;
  @Input()
  visible: string;
  data: Object = {
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };

  labels: any;
  constructor(
    private fb: FormBuilder, 
    private translate: TranslateService,  
    private tiposComunicadoService: TiposComunicadoService,
    private caixaPostalService: CaixaPostalService,
    ) {
    
  }

  ngOnInit() {
    this.criarFormulario();

    this.tiposComunicadoService.obterTiposComunicado().subscribe(
      res => {
        this.tiposComunicado = res.resultado;
      },
      error => console.log(error)
    );
  }

  ngAfterViewChecked() {
    const sessaoFiltro = JSON.parse(sessionStorage.getItem('filtro'));
    if(sessaoFiltro) {
      if(sessaoFiltro.codigo_tipo_comunicado) {
        this.filtroAtivo = true;
      } else {
        this.filtroAtivo = false;
      }
    } else {
      this.filtroAtivo = false;
    }
  }
  
  pesquisa(){
    if(this.visible === 'comunicado'){
      this.visible = null;
    } else {
      this.visible = 'comunicado';
    }

    let obj = {};
      this.onFilter.emit(obj);
      this.onPageUpdate.emit(1);
  }

  pesquisarTipoComunicado(){    
    var parametros = {};
    parametros['codigo_tipo_comunicado'] = this.comunicadoSelecionado;
    
    let comunicado = this.tiposComunicado.filter(res => res.codigo == parametros['codigo_tipo_comunicado']);
    this.comunicado = comunicado[0].descricao;

    this.filtroAtivo = true;
    this.visible = '';
    this.onFilter.emit(parametros);

    this.onFilter.emit(parametros);
    this.onPageUpdate.emit(1);

    let obj = {};
      this.onFilter.emit(obj);
      this.onPageUpdate.emit(1);
  }
  
  criarFormulario() {
    this.searchForm = this.fb.group({
      comunicadoSelecionado: '',
    });
  }

  limparFiltros() {  
    let parametros = {};
    parametros['codigo_tipo_comunicado'] = this.comunicadoSelecionado;
    this.onClear.emit(parametros);
   
    this.comunicado = 'Tipo Comunicado';
    
    this.searchForm.patchValue({
      comunicadoSelecionado: '',
    });

    this.visible = null;
    this.filtroAtivo = false;
    this.comunicadoSelecionado = null;

    let storage = JSON.parse(sessionStorage.getItem('filtro'));
    if(storage.codigo_tipo_comunicado) {
      delete storage.codigo_tipo_comunicado;
    }

    sessionStorage.setItem('filtro', JSON.stringify(storage));

    let obj = {};
      this.onFilter.emit(obj);
      this.onPageUpdate.emit(1);
  }

  resetarParametros() {
    this.visible = null;
    this.filtroAtivo = false;
    this.comunicadoSelecionado = null;

    let obj = {};
      this.onFilter.emit(obj);
      this.onPageUpdate.emit(1);
  }

}
