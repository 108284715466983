import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class CboService {

    token: string;
    private httpClient: HttpClient;

    constructor(handler: HttpBackend) {
        this.httpClient = new HttpClient(handler);
    }

    getToken(obj) {
        const header = new HttpHeaders()
        .set(
            "content-type",
            "application/json"
        )
        .set(
            "APP_ID",
            "a0bcdeda-f337-4716-a270-aea421c966d0"
        )
        return this.httpClient.post(`https://pruc9999999.eiconbrasil.com.br/loginunico/api/v2/token`, obj, {headers: header})
    }

    getCbo() {
        this.token = localStorage.getItem('tokenCbo');
        const header = new HttpHeaders()
        .set(
            "Authorization",
            `Bearer ${this.token}`
        )
        return this.httpClient.get(`https://cbo.eiconbrasil.com.br/api/v2/ocupacao/obter-todos`, {headers: header})
    }
}