import { Component, ViewEncapsulation } from '@angular/core';
import { UserService } from "../../../service/user-service/user.service";
import { Subscription } from "rxjs/Subscription";

@Component({
  selector: '[app-sidebar]',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  host: { 'class': 'sidebarBox' },
  encapsulation: ViewEncapsulation.None
})

export class SidebarComponent {
  subscription: Subscription;
  private termoPesquisa: string;
  private selectedItem = 0;
  private estadoMenu = {
    sidebarFechada: true
  };
  private icon = '#menu'
  constructor(private userService: UserService) {
    this.subscription = this.userService.getInfo().subscribe(info => {
      this.estadoMenu.sidebarFechada = info.sidebarFechada;
      if(!this.estadoMenu.sidebarFechada) {
        this.icon = '#close'
      } else {
        this.icon = '#menu'
      }
    });
  }

  fecharSidebar() {
    this.estadoMenu.sidebarFechada = !this.estadoMenu.sidebarFechada;
    this.userService.addData(this.estadoMenu);
  }
}
