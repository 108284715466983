import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LacunaWebPKI, CertificateModel, ExceptionModel } from 'web-pki';
import { CertificadoService } from '../../../../../service/certificado/certificado.service';
import { CaixaPostalService } from '../../../../../service/caixa-postal/caixa-postal.service';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs/Subject';

import swal from 'sweetalert2';
import { DeclareVarStmt } from '@angular/compiler';
import { getSwal } from '../../../../../utils/swalObject';
import moment from 'moment';
import { ProcuracoesService } from 'src/app/service/procuracoes/procuracoes.service';

@Component({
  selector: 'app-certificados',
  templateUrl: './certificados.component.html',
  styleUrls: ['./certificados.component.scss'],
})
export class CertificadosComponent implements OnInit {
  private pki: LacunaWebPKI;
  searchResults: Array<any> = [];
  buscando = false;
  encontrado = true;
  serverData: Object = {};
  disabled = false;
  loading = false;
  today: Date = new Date();
  certificado: any = null;
  protocolo: number = null;
  lista: number = null;
  documento: object = null;
  cpfCnpjContribuinte: string;
  cpfCnpjRepresentante: string = '';
  dadosSessao = {}

  constructor(
    private caixaPostalService: CaixaPostalService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private config: CertificadoService,
    private procuracoesService: ProcuracoesService
  ) {
    this.route.paramMap.subscribe(params => {
      this.protocolo = parseInt(params.get('id'));
      this.lista = parseInt(params.get('lista'));
    });
    this.pki = new LacunaWebPKI(config.webPkiLicense);
  }

  ngOnInit() {
    this.buscando = true;
    this.pki.init({
      ngZone: this.ngZone,
      ready: this.onWebPkiReady,
      notInstalled: this.onWebPkiNotInstalled,
    });
    let dadosLocais = JSON.parse(localStorage.getItem('session'));
    this.cpfCnpjContribuinte = dadosLocais.usuario.cpfCnpj;
    this.procuracoesService.getRepresentanteLegal(this.cpfCnpjContribuinte)
      .subscribe(res => {
        if(res['resultado']['outorgante']) {
          this.cpfCnpjRepresentante = res['resultado']['outorgante']['cpfCnpj']
        }
      })
  }

  private onWebPkiReady = () => {
    this.pki.listCertificates({}).success(certs => {
      this.searchResults = [];
      certs.forEach((ct, index) => {
        let validityDate = new Date(ct.validityEnd);
        console.log(this.cpfCnpjRepresentante, this.cpfCnpjContribuinte, ct.pkiBrazil.cpf, ct.pkiBrazil.cnpj);
        
        if (validityDate > this.today && (ct.pkiBrazil.cpf == this.cpfCnpjContribuinte) || (ct.pkiBrazil.cnpj == this.cpfCnpjContribuinte) ||
        (ct.pkiBrazil.cpf == this.cpfCnpjRepresentante) || (ct.pkiBrazil.cnpj == this.cpfCnpjRepresentante)) {
          ct['validityStartFormated'] = moment(ct.validityStart).format(
            'DD/MM/YYYY',
          );
          ct['validityEndFormated'] = moment(ct.validityEnd).format(
            'DD/MM/YYYY',
          );
          this.searchResults.push(ct);
        } 
      });
      if(this.searchResults.length == 0) {
        this.encontrado = false;
      }
      this.buscando = false;
    });
  };

  private onWebPkiNotInstalled = (status, message) => {
    this.buscando = false;
    this.pki.redirectToInstallPage();
  };

  onSelect(e, certificado) {
    this.loading = true;
    this.certificado = certificado;
    this.disabled = !this.disabled;
    this.pki.readCertificate(certificado.thumbprint).success(certEncoded => {
      this.handleDocument(certEncoded);
    });
  }

  handleDocument(certificado) {
    this.caixaPostalService
      .gerarDocumentoCiencia(this.protocolo, certificado)
      .subscribe(
        res => {
          this.signDocument(res.resultado);
        },
        error => {
          swal.fire(
            getSwal(
              'error',
              'Ops!',
              'Ocorreu um erro e a mensagem não pôde ser assinada, tente novamente',
              'ok',
              null,
            ),
          ).then(res => {
            if (this.lista === 1) {
              this.router.navigate([`caixa-postal/mensagens`]);
            }
            else {
              this.router.navigate([`caixa-postal/mensagem/${this.protocolo}`]);
            }
          });
        },
      );
  }

  signDocument(response) {
    this.documento = response;
    const thumbprint = this.certificado.thumbprint;
    const hash = response.toSignHash;
    const digestAlgorithm = response.digestAlgorithm;
    this.pki
      .signHash({
        thumbprint: thumbprint,
        hash: hash,
        digestAlgorithm: digestAlgorithm,
      })
      .success(signature => {
        this.documento['assinatura'] = signature;
        this.caixaPostalService
          .darCiencia(this.protocolo, this.documento)
          .subscribe(
            res => {
              this.loading = false;

              swal.fire(
                getSwal(
                  'success',
                  'Sucesso',
                  'A ciência do comunicado foi efeituada com êxito.',
                  'ok',
                  null,
                ),
              ).then(res => {
                this.router.navigate([
                  `caixa-postal/mensagem/${this.protocolo}`,
                ]);
              });
            },
            error => {
              this.loading = false;

              swal.fire(
                getSwal(
                  'error',
                  'Ops!',
                  'Ocorreu um erro e o comunicado não pôde ser assinado, tente novamente',
                  'ok',
                  null,
                ),
              ).then(res => {
                if (this.lista === 1) {
                  this.router.navigate([`caixa-postal/mensagens`]);
                }
                else {
                  this.router.navigate([`caixa-postal/mensagem/${this.protocolo}`]);
                }
              });
            },
          );
      });
  }
}
