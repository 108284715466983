import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import { ProcuracaoRoutingModule } from './procuracao.routing.module';
import { DataTableModule } from 'ng2-data-table';
import { DpDatePickerModule } from 'ng2-date-picker';
import { CommonAppModule } from '../../features/common/common.module';
import { ProcuracaoComponent } from './procuracao/procuracao.component';
import { GridProcuracaoComponent } from './grid-procuracao/grid-procuracao.component';
import { FilterComponent } from './grid-procuracao/components/filter.component';
import { OutorgarComponent } from './outorgar/pages/outorgar/outorgar.component';
import { CertificadosComponent } from './outorgar/pages/certificados/certificados.component';

import { SharedModule } from '../../shared.module';

import { TreeviewModule } from 'ngx-treeview';

@NgModule({
  imports: [
    CommonModule,
    DpDatePickerModule,
    CommonAppModule,
    NgxMaskModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ProcuracaoRoutingModule,
    DataTableModule,
    SharedModule,
    TreeviewModule.forRoot()
  ],

  exports: [ProcuracaoComponent, GridProcuracaoComponent],

  declarations: [
    ProcuracaoComponent,
    GridProcuracaoComponent,
    OutorgarComponent,
    CertificadosComponent,
    FilterComponent
  ],
  providers: []
})
export class ProcuracaoModule {}
