import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs/Observable";
import { environment } from "src/environments/environment";
import 'rxjs/add/operator/map'

@Injectable()
export class ServicoComunicacaoService {
    url: string = environment.BASE_URL;

    constructor(private http: HttpClient) {

    }
    
    obterServidoresComunicacao(codigoCliente: number): Observable<any> {
        return this.http.get(`${this.url}/clientes/${codigoCliente}/servidoresComunicacao`)
            .map(data => data);
    }
}