import { Component, OnInit, Output, Input, EventEmitter, OnChanges, AfterViewChecked } from '@angular/core';

import moment from 'moment-timezone';
import { Moment } from 'moment';

@Component({
  selector: 'app-filtro-periodo',
  templateUrl: 'filtro-periodo.component.html',
  styleUrls: ['./filtro-periodo.component.scss'],
})
export class FiltroPeriodoComponent implements OnInit, AfterViewChecked {
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageUpdate: EventEmitter<number> = new EventEmitter<number>();

  @Input() resetFilter: boolean;
  @Input() filtroAtivo: any = false;

  periodo: boolean = false;
  dataInicial: any = null;
  dataFinal: any = null;
  @Input()
  visible: string;
  inicio: moment.Moment;
  fim: moment.Moment;
  dataInclusaoValida: boolean = false;
  dataFimInclusaoValida: boolean = false;

  obj = {
    data_inicial: moment.Moment,
    data_final: moment.Moment,
  };
  configuracaoData:Object = {
    locale:'pt-br'
  }

  constructor() {}

  ngOnInit() {}

  ngAfterViewChecked() {
    const sessaoFiltro = JSON.parse(sessionStorage.getItem('filtro'));
    if(sessaoFiltro) {
      if(sessaoFiltro.data_inicial || sessaoFiltro.data_final) {
        this.inicio = moment(sessaoFiltro.data_inicial);
        this.fim = moment(sessaoFiltro.data_final);
        this.filtroAtivo = true;
      } else {
        this.filtroAtivo = false;
      }
    } else {
      this.filtroAtivo = false;
    }
  }

  selecionarDataInicial(event) {
    const dataSelecionada = moment(event, 'DD-MM-YYYY');
    const dataAtual = moment();

    if (dataSelecionada.isAfter(dataAtual, 'day')) {
      this.dataInclusaoValida = false;
      return;
    }

    if (this.inicio) {
      this.dataInicial = this.inicio;
      this.dataInclusaoValida = true;
    }

    this.obj['data_inicial'] = moment(this.inicio, 'DD-MM-YYYY').format();
    if (this.inicio > this.fim) {
      setTimeout(() => {
        this.obj['data_final'] = null; //  moment.Moment;
        this.fim = null; //moment.Moment;
      }, 100);
    }
  }

  selecionarDataFinal(event) {
    const dataAtual = moment();
    const dataSelecionadaFim = moment(this.fim);
    if (dataSelecionadaFim.isAfter(dataAtual, 'day')) {
      this.dataFimInclusaoValida = false;
      return;
    }

    if (this.fim) {
      this.dataFinal = this.fim;
      this.dataFimInclusaoValida = true;
    }
    this.obj['data_final'] = moment(this.fim, 'DD-MM-YYYY').format();

  }

  pesquisarPeriodo() {
    this.onFilter.emit(this.obj);
    this.periodo = true;
    this.filtroAtivo = true;

    this.onFilter.emit(this.obj);
    this.onPageUpdate.emit(1);
  }

  limparFiltros() {
    this.onClear.emit(this.obj);
    this.obj = {
      data_inicial: moment.Moment,
      data_final: moment.Moment,
    };
    this.fim = moment.Moment;
    this.inicio = moment.Moment;
    this.periodo = false;
    this.filtroAtivo = false;

    let storage = JSON.parse(sessionStorage.getItem('filtro'));
    if(storage.data_inicial && storage.data_final) {
      delete storage.data_inicial
      delete storage.data_final
    }

    this.periodo = false;
    this.dataInclusaoValida = false;

    sessionStorage.setItem('filtro', JSON.stringify(storage));
  }

  resetarParametros() {
    this.fim = moment.Moment;
    this.inicio = moment.Moment;
  }
}
