import { Component, OnInit, ɵConsole } from "@angular/core";
import { trigger, transition, animate, style } from "@angular/animations";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  MinLengthValidator,
} from "@angular/forms";
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { WizardService } from "../../../../service/wizard/wizard.service";
import { CadastroService } from "../../../../service/cadastro/cadastro.service";
//import { CepService } from "../../../../service/cep/cep.service";
import { ConsultaUsuarioService } from "../../../../service/consulta-usuario/consultar.user.service";



import { TranslateService } from "@ngx-translate/core";
import { PatternsInterface } from "src/app/patterns/patterns.interface";

import swal from 'sweetalert2';
import { getSwal } from "../../../../utils/swalObject";
import { CboService } from "src/app/service/cbo/cbo.service";


@Component({
  selector: "app-dados-basicos",
  templateUrl: "dados-basicos.component.html",
  styleUrls: ["./dados-basicos.component.scss"],
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateX(-100%)" }),
        animate("300ms ease-in", style({ transform: "translateX(0%)" })),
      ]),
    ]),
  ],
})
export class DadosBasicosComponent implements OnInit {
  index = 2;
  signUpForm: FormGroup;
  loading: boolean = false;
  labels: any;
  data = {};
  pessoa: string = "fisica";
  cnpjMask = new PatternsInterface().cnpj;
  cpfMask = new PatternsInterface().cpf;
  celMask = new PatternsInterface().cel;
  phoneMask = new PatternsInterface().phone;
  private routeSubscription: any;
  perfilContribuinte = false;
  cadastroContribuinte = false;
  id: number = null;
  prefeituras = null;
  perfil: string = ''
  cadastroIntegrado: boolean = false
  exibirPopUp: boolean = false;
  personalidadeJuridica: any;
  codigoCBO: any;
  setorAtuacao: any;
  tipoPersonalidade: any;
  prefeitura: any;
  telefoneCelular: any;
  servicoSms: number;
  token: string;
  servicoEmail: number;

  constructor(
    private wizardService: WizardService,
    private fb: FormBuilder,
    private cadastroService: CadastroService,
    private consultaUsuario: ConsultaUsuarioService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private translate: TranslateService,
    private cboService: CboService
  ) {
    this.wizardService.updateIndex(this.index);
    this.pessoa = this.wizardService.userData.tipo;
    this.setLabels();
  }

  ngOnInit() {
    let obj = {
      grant_type: "password",
      apikey: "CP7049PivNaOKpQE1gLt7mSJdGdJ0O9adGLhozrbd+cV5HG4Khk+8mnqISweKrQ/ZqLMGQxJTP2Q97A19o/vXg==",
      tipoLogin: 5
    }
    this.cboService.getToken(obj).subscribe(
      res => {
        this.token = res['access_token'];
        localStorage.setItem('tokenCbo', this.token);
      }
    )

    setTimeout(() => {
      this.cboService.getCbo().subscribe(
        res => {
          console.log(res);
        }
      )
    }, 1500);
     
    
    this.personalidadesJuridicasativos();
    this.setorAtuacaosativos();

    if (localStorage.getItem("data")) {
      this.data = JSON.parse(localStorage.getItem("data"));
      this.data["perfil"] = localStorage.getItem("perfil");
      this.data["tipo"] = localStorage.getItem("tipo");
      this.data["certificado"]= localStorage.getItem("certificado");
      this.pessoa = localStorage.getItem("tipo");
      this.wizardService.userData = this.data;
      this.prefeitura = this.wizardService.userData.prefeituras[0]
      this.servicoSms = this.prefeitura.configuracao.servicoSms;
      this.servicoEmail = this.prefeitura.configuracao.servicoEmail;
      // console.log('entrou antreaass :', this.data)
    } else {
      this.data = this.wizardService.userData;
      this.prefeitura = this.wizardService.userData.prefeituras[0]
      this.servicoSms = this.prefeitura.configuracao.servicoSms;
      this.servicoEmail = this.prefeitura.configuracao.servicoEmail;
    }

    if (
      Object.keys(this.wizardService.userData.infoBasica).length === 0 &&
      this.wizardService.userData.infoBasica.constructor === Object
    ) {
      this.criarFormulario();
    } else {
      this.edit();
      this.criarFormulario();
    }

    this.routeSubscription = this.route.queryParams.subscribe((params) => {
      if (params["cpf"]) {
        this.signUpForm.controls["cnpjContribuinte"].disable();
        this.signUpForm.controls["razaoSocialContribuinte"].disable();
        this.signUpForm.controls.cnpjContribuinte.setValue(params.cnpj);
        this.signUpForm.controls.razaoSocialContribuinte.setValue(
          params.companyName
        );
        this.signUpForm.controls["cnpjContribuinte"].updateValueAndValidity();
        this.signUpForm.controls[
          "razaoSocialContribuinte"
        ].updateValueAndValidity();
      }
    });

  }


  ngAfterViewInit() {
    this.buscaApi();


  }

  async edit() {
    let criaFormulario = await this.criarFormulario();
    this.updateForm();
  }

  criarFormulario() {

    if (this.prefeitura.configuracao.exigeCertificadoCnpj == 1) {
      this.signUpForm = this.fb.group({

        comboPersonalidadeJuridicaContribuinte: ["", Validators.compose([Validators.required])],
        comboSetorAtuacaoContribuinte: ["", Validators.compose([Validators.required])],


        personalidadeJuridicaContribuinte: { codigo: '' },
        setorAtuacaoContribuinte: { codigo: '' },


        cnpjContribuinte: [
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(14),
            Validators.maxLength(18),
          ]),
        ],
        razaoSocialContribuinte: [
          "",
          Validators.compose([Validators.required, Validators.maxLength(160)]),
        ],
        inscricao: [
          ""
        ],
        telefoneCelular: [
          "",
          Validators.compose([Validators.required, Validators.maxLength(11), Validators.minLength(10)]),
        ],
        email: [
          "",
          Validators.compose([Validators.required, Validators.maxLength(60)]),
        ],
        ativo: [
          false,
          Validators.required,
        ],
        liConcordo: [
          false,
          Validators.compose([Validators.required]),
        ]
      });
    } else {
      this.signUpForm = this.fb.group({

        comboPersonalidadeJuridicaContribuinte: ["", Validators.compose([Validators.required])],
        comboSetorAtuacaoContribuinte: ["", Validators.compose([Validators.required])],

        cnpjContribuinte: [
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(14),
            Validators.maxLength(18),
          ]),
        ],
        razaoSocialContribuinte: [
          "",
          Validators.compose([Validators.required, Validators.maxLength(160)]),
        ],
        inscricao: [
          ""
        ],
        telefoneCelular: [
          "",
          Validators.compose([Validators.required, Validators.maxLength(11), Validators.minLength(10)]),
        ],
        email: [
          "",
          Validators.compose([Validators.required, Validators.maxLength(60)]),
        ],
        ativo: [
          false,
          Validators.required,
        ],
        liConcordo: [
          false,
          Validators.compose([Validators.required]),
        ]
      });

    }

    if (this.prefeitura.configuracao.exigeCertificadoCnpj === 0) {
      this.signUpForm.controls["comboPersonalidadeJuridicaContribuinte"].setValidators([]);
      this.signUpForm.controls["comboSetorAtuacaoContribuinte"].setValidators([]);
    }

    if (this.pessoa === "fisica") {
      this.signUpForm.controls['comboPersonalidadeJuridicaContribuinte'].setValue("0");
      this.signUpForm.controls['comboSetorAtuacaoContribuinte'].setValue("0");
      this.signUpForm.addControl("telefoneCelular", new FormControl());
      this.signUpForm.addControl("email", new FormControl());
      this.signUpForm.controls["telefoneCelular"].setValidators([
        Validators.compose([Validators.required, Validators.maxLength(11), Validators.minLength(10)]),
      ]);
      this.signUpForm.controls["telefoneCelular"].updateValueAndValidity();
      this.signUpForm.controls["email"].setValidators([
        Validators.compose([Validators.required, Validators.maxLength(60)]),
      ]);
      this.signUpForm.controls["email"].updateValueAndValidity();
    }

    if(this.servicoSms === 0) {
      this.signUpForm.controls["telefoneCelular"].setValidators([]);
      this.signUpForm.controls["email"].setValidators([]);      
    }

    return this.signUpForm;
  }

  razaoSocialInvalid(){
    if(this.signUpForm.controls["razaoSocialContribuinte"].value.trim().length == 0) {
      this.signUpForm.controls["razaoSocialContribuinte"].setValue("");

    }
  }

  updateForm() {
    let data = this.wizardService.userData.infoBasica;


    for (let key in data) {
      if (
        typeof key !== "undefined" &&
        key !== "telefoneCelular" &&
        key !== "dddTelefoneCelular" &&
        key !== "pessoaFisica" &&
        key !== "personalidadeJuridicaContribuinte" &&
        key !== "compoPersonalidadeJuridicaContribuinte" &&
        key !== "pessoa" &&
        key !== "setorAtuacaoContribuinte" &&
        key !== "comboSetorAtuacaoContribuinte"
      ) {
        this.signUpForm.controls[key].patchValue(data[key]);
      } else {

        let telefoneCelular = data["dddTelefoneCelular"]
          .replace(/\D+/g, "")
          .concat(data["telefoneCelular"].replace(/\D+/g, ""));
        this.signUpForm.controls["telefoneCelular"].setValue(telefoneCelular);
        this.signUpForm.controls['comboPersonalidadeJuridicaContribuinte'].setValue(data["comboPersonalidadeJuridicaContribuinte"]);
        this.signUpForm.controls['comboSetorAtuacaoContribuinte'].setValue(data["comboSetorAtuacaoContribuinte"]);
      }
    }


    this.signUpForm.markAllAsTouched();
  }

  consultarUsuario(id: string) {

    if (this.cadastroIntegrado === true) {
      this.consultaUsuario.getApiExterna(id).subscribe(async res => {
        if (res.resultado.length > 1) {
          this.exibirPopUp = true;
          this.data = res;
          var codigo = 1
          this.data['resultado'].map(item => {
            item['codigo'] = codigo;
            item['checked'] = false;
            codigo++;
          });
        } else {
          this.contribuiteSelecionado(res['resultado'])
        }
      }
        , error => {
          swal.fire(
            getSwal(
              'error',
              error.error.excecao.recomendacao,
              '',
              'ok',
              null
            )
          );
        }
      );

    } else {

      if (this.signUpForm.controls.cnpjContribuinte.valid) {
        this.loading = true;

        if (this.pessoa === "fisica") {
          this.consultaUsuario.consultarUsuario(id).subscribe(
            (res) => {
              this.loading = false;
              let data = res.resultado;
              this.wizardService.userData["id"] = data.cpfCnpj;
              this.wizardService.userData["codigo"] = data.codigo;
              const docs = this.transformDoc(data.cpfCnpj);

              let perfis = res.resultado.perfis;

              perfis.forEach((element) => {
                if (element.codigo === 2) {
                  this.perfilContribuinte = true;
                  return;
                } else {
                  this.perfilContribuinte = false;
                }
              });

              if (this.perfilContribuinte) {
                // contribuinte === 2
                swal
                  .fire(
                    getSwal(
                      "success",
                      "Usuário já cadastrado",
                      "Você já está cadastrado em nosso sistema, defina sua senha para acessá-lo",
                      "ok",
                      null
                    )
                  )
                  .then((res) => {
                    this.router.navigate(["index"]);
                  });
              } else if (data.situacao == 2) {
                // contribuinte === 2
                swal
                  .fire(
                    getSwal(
                      "error",
                      "Usuário Inativo",
                      "Você já está cadastrado em nosso sistema, mas está com acesso inativo, favor solicitar apoio ao administrador",
                      "ok",
                      null
                    )
                  )
                  .then((res) => {
                    this.router.navigate(["index"]);
                  });
              } else {
                // procurador === 1
                swal
                  .fire(
                    getSwal(
                      "success",
                      `CPF ${docs} já cadastrado`,
                      "Você já está cadastrado como Procurador. Deseja também ter um cadastro de Contribuinte ?",
                      "Sim",
                      "Não"
                    )
                  )
                  .then((res) => {
                    if (res.value) {
                      this.cadastroContribuinte = true;
                      setTimeout(() => {
                        if (data.nomeRazaoSocial) {
                          this.signUpForm.controls.razaoSocialContribuinte.setValue(
                            data.nomeRazaoSocial
                          );
                          this.signUpForm.controls[
                            "razaoSocialContribuinte"
                          ].disable();
                        }
                        if (data.email) {
                          this.signUpForm.controls.email.setValue(data.email);
                          this.signUpForm.controls["email"].disable();
                        }
                        if (
                          data.ddd &&
                          data.celular
                        ) {
                          this.signUpForm.controls.telefoneCelular.setValue(
                            data.ddd +
                            data.celular
                          );
                          this.signUpForm.controls["telefoneCelular"].disable();
                        }
                        if(
                          data.detalhe &&
                          data.detalhe.setorAtuacao
                        ) {
                          this.signUpForm.controls.comboSetorAtuacaoContribuinte.setValue(
                            data.detalhe.setorAtuacao.codigo
                          );
                          this.signUpForm.controls["comboSetorAtuacaoContribuinte"].disable();
                        }
                        if(
                          data.detalhe &&
                          data.detalhe.personalidadeJuridica
                        ) {
                          this.signUpForm.controls.comboPersonalidadeJuridicaContribuinte.setValue(
                            data.detalhe.personalidadeJuridica.codigo
                          );
                          this.signUpForm.controls["comboPersonalidadeJuridicaContribuinte"].disable();
                        }
                        if(
                          data.detalhe &&
                          data.detalhe.inscricaoMunicipal
                        ) {
                          this.signUpForm.controls.inscricao.setValue(
                            data.detalhe.inscricao
                          );
                          this.signUpForm.controls["comboSetorAtuacaoContribuinte"].disable();
                        }
                      }, 150);
                    } else {
                      setTimeout(() => {
                        this.router.navigate(["/index"]);
                      }, 150);
                    }
                  });
              }
            },
            (error) => {
              if (error.error.excecao.codigo && error.error.excecao.codigo == 'E000') {
                swal.fire(
                  getSwal(
                    'error',
                    '',
                    error.error.excecao.recomendacao,
                    'ok',
                    null
                  )
                ).then(() => this.signUpForm.get('cnpjContribuinte').setValue(null));
              }
              this.loading = false;
            }
          );
        } else {
          this.consultaUsuario.consultarUsuario(id).subscribe(
            (res) => {
              this.loading = false;
              let data = res.resultado;
              this.wizardService.userData["id"] = data.cpfCnpj;
              this.wizardService.userData["codigo"] = data.codigo;
              if (data.situacao === 0) {
                this.signUpForm.patchValue({
                  cnpjContribuinte: data.cpfCnpj,
                  razaoSocialContribuinte: data.nomeRazaoSocial,
                  telefoneCelular:
                      data.ddd &&
                      data.celular
                      ? data.ddd
                        .replace(/\D+/g, "")
                        .concat(
                          data.celular.replace(/\D+/g, "")
                        )
                      : "",
                  email:
                    data.email
                      ? data.email
                      : "",
                  inscricao:
                    data.detalhe && data.detalhe.inscricaoMunicipal
                      ? data.detalhe.inscricaoMunicipal
                      : "",
                  comboSetorAtuacaoContribuinte:
                    data.detalhe && data.detalhe.setorAtuacao
                      ? data.detalhe.setorAtuacao.codigo
                      : "",    
                  comboPersonalidadeJuridicaContribuinte:
                    data.detalhe && data.detalhe.personalidadeJuridica
                      ? data.detalhe.personalidadeJuridica.codigo
                      : "",   
                });

                let obj = this.signUpForm.value
                for (let key in obj) {
                  if (key !== 'cnpjContribuinte' && this.signUpForm.controls[key].value !== '') {
                    this.signUpForm.controls[key].disable();
                  }
                }
              } else {
                const docs = this.transformDoc(data.cpfCnpj);
                swal
                  .fire(
                    getSwal(
                      "success",
                      `Contribuinte ${docs} - ${data.nomeRazaoSocial} já cadastrado`,
                      "Favor acessar com Certificado Digital ou através de seu representante legal.",
                      "ok",
                      null
                    )
                  )
                  .then((res) => {
                    this.router.navigate(["index"]);
                  });
              }
            },
            (error) => {
              if (error.error.excecao.codigo && error.error.excecao.codigo == 'E000') {
                swal.fire(
                  getSwal(
                    'error',
                    '',
                    error.error.excecao.recomendacao,
                    'ok',
                    null
                  )
                ).then(() => this.signUpForm.get('cnpjContribuinte').setValue(null));
              }
              this.loading = false;
            }
          );
        }
      }

    }
  }

  transformDoc(value: any): any {
    if (value && value.length === 11) {
      return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(
        6,
        3
      )}-${value.substr(9, 2)}`;
    } else if (value && value.length === 14) {
      return `${value.substr(0, 2)}.${value.substr(2, 3)}.${value.substr(
        5,
        3
      )}/${value.substr(8, 4)}-${value.substr(12, 2)}`;
    }

    return value;
  }

  
  proximo() {
    let data = this.signUpForm.getRawValue();
    data["dddTelefoneCelular"] = data["telefoneCelular"]
      .replace(/\D+/g, "")
      .substring(0, 2);
    data["telefoneCelular"] = data["telefoneCelular"]
      .replace(/\D+/g, "")
      .substr(2, 11)
    data["pessoa"] = this.pessoa;
    this.wizardService.userData.infoBasica = data;

    

    if (this.prefeitura.configuracao.exigeCertificadoCnpj == 1) {
      data.personalidadeJuridicaContribuinte.codigo = data.comboPersonalidadeJuridicaContribuinte;
      data.setorAtuacaoContribuinte['codigo'] = data.comboSetorAtuacaoContribuinte;
    }

    if (this.pessoa === "fisica") {

      this.wizardService.userData.infoBasica = data;

      this.wizardService.userData.infoBasica["pessoaFisica"] = true;
      localStorage.setItem("data", JSON.stringify(this.wizardService.userData));

      // this.router.navigate(['cadastro/procurador/resumo']);
      if (this.cadastroContribuinte === true) {
        this.router.navigate(["cadastro/contribuinte/resumo-atribuir-contribuinte"]);
      } else {
        this.router.navigate(["cadastro/contribuinte/resumo"]);
      }

    } else if (this.pessoa === 'juridica' && localStorage.getItem('certificado')) {
      let certificado = localStorage.getItem('certificado');
      let obj = JSON.parse(certificado);
      console.log('certificado: ' +  JSON.stringify(obj));
      data["nonce"] = obj.resultado.nonce;

      this.wizardService.userData.infoBasica["pessoaFisica"] = false;
      this.wizardService.userData.infoBasica = data;
      localStorage.setItem("data", JSON.stringify(this.wizardService.userData));
      this.router.navigate(["cadastro/contribuinte/representante"]);
      console.log("passou");
      

    } else if (this.pessoa === 'juridica') {
      this.wizardService.userData.infoBasica["pessoaFisica"] = false;
      this.wizardService.userData.infoBasica = data;
      
      localStorage.setItem("data", JSON.stringify(this.wizardService.userData));
      
      // return false
      // this.router.navigate(['cadastro/procurador/representante']);
      this.router.navigate(["cadastro/contribuinte/representante"]);
    }

  }

  desistir() {
    this.wizardService.resetData();
    this.router.navigate(["/index"]);
  }

  voltar() {
    this.index = this.index - 1;
    this.wizardService.updateIndex(this.index);
    this.location.back();
  }

  setLabels() {
    this.labels = {
      title: this.translate.instant("GLOBAIS.SEUSDADOS"),
      cnpj: this.translate.instant("GLOBAIS.CNPJ"),
      cpf: this.translate.instant("GLOBAIS.CPF"),
      inscricao: this.translate.instant("GLOBAIS.INSCRICAO"),
      nome: this.translate.instant("GLOBAIS.NOME"),
      razaoSocial: this.translate.instant("GLOBAIS.RAZAOSOCIAL"),
      telefone: this.translate.instant("GLOBAIS.TELEFONE"),
      comercial: this.translate.instant("GLOBAIS.COMERCIAL"),
      email: this.translate.instant("GLOBAIS.EMAIL"),
      exigirAssinatura: this.translate.instant("GLOBAIS.EXIGIRASSINATURA"),
      sim: this.translate.instant("GLOBAIS.SIM"),
      nao: this.translate.instant("GLOBAIS.NAO"),
      voltar: this.translate.instant("GLOBAIS.VOLTAR"),
      desistir: this.translate.instant("GLOBAIS.DESISTIR"),
      prosseguir: this.translate.instant("GLOBAIS.PROSSEGUIR"),
    };
  }

  contribuiteSelecionado(event) {
    this.exibirPopUp = false;
    event = event[0];
    this.signUpForm.patchValue({
      razaoSocialContribuinte: event.razaoSocialContribuinte,
      email: event.email,
      telefoneCelular: event.dddTelefoneCelular + event.telefoneCelular,

      cpfRepresentanteLegal: event.cpfRepresentanteLegal,
      celularRepresentanteLegal: event.dddCelularRepresentanteLegal + event.celularRepresentanteLegal,
      emailRepresentanteLegal: event.emailRepresentanteLegal,
      nomeRepresentanteLegal: event.nomeRepresentanteLegal

    });
    
  }

  fecharPopUp(event) {
    this.exibirPopUp = false;
  }


  buscaApi() {

    this.consultaUsuario.getAutoCadastro().subscribe(
      res => {
        // console.log('dados:', res.resultado);
        this.cadastroIntegrado = res.resultado;
      },
      error => {
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro e o Ambiente não pôde ser carregado.',
            'ok',
            null
          )
        );
      }
    );
  }

  personalidadesJuridicasativos() {
    this.consultaUsuario.getPersonalidadesJuridicasAtivos().subscribe(
      res => {
        // var i = 0;
        // res.resultado.forEach(item => {
        //   res.resultado[i]["selecionado"] = "false"
        //   if(this.wizardService.userData.infoBasica.personalidadeJuridicaContribuinte){
        //     if(this.wizardService.userData.infoBasica.personalidadeJuridicaContribuinte == res.resultado[i].codigo){
        //     res.resultado[i]["selecionado"] = "true"
        //     }
        //   }

        //   i++;
        // });
        this.personalidadeJuridica = res.resultado;
        // console.log('this.personalidadeJuridica sele', this.personalidadeJuridica)

      },
      error => {
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades juridicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  setorAtuacaosativos() {
    this.consultaUsuario.getSetoresAtuacaoAtivos().subscribe(
      res => {
        this.setorAtuacao = res.resultado;
      },
      error => {
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades juridicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  get f(){ return this.signUpForm.controls; }

  telefoneMask(celular){
    const isCelular = celular == null ? true : celular.length == 11 ? true : false;
    return celular ? '(00) 0000-00000' : '(00)0000-00009';
  }
  
}
