import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterPipe } from '../../../pipes/filter.pipe';
import { RecuperarSenhaService } from '../../../service/recuperar-senha/recuperar-senha.service';
import swal from 'sweetalert2';
import { getSwal } from '../../../utils/swalObject';

@Component({
  selector: 'app-confirmacao',
  templateUrl: 'confirmacao.component.html',
  styleUrls: ['./confirmacao.component.scss'],
})
export class ConfirmacaoComponent implements OnInit {
  tipo = null;
  codigoValidacaoForm: FormGroup;
  sucesso = false;
  confirmar: boolean = false;
  codigoUsuario: number;
  subscription: Subscription;
  private routeSubscription: any;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private recuperarSenhaService: RecuperarSenhaService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.routeSubscription = this.route.queryParams.subscribe(params => {
      this.tipo = params['tipo'];
      this.codigoUsuario = params['codigoUsuario'];
    });
    this.criarFormulario();
  }

  criarFormulario() {
    this.codigoValidacaoForm = this.fb.group({
      codigoValidacao: ['', Validators.required],
    });
  }

  validarCodigo() {
    // let codigo = this.recuperarSenhaService.codigoUsuario;
    let codigoVerificacao = this.codigoValidacaoForm.controls.codigoValidacao
      .value;
    this.recuperarSenhaService
      .validarCodigoSMS(this.codigoUsuario, codigoVerificacao)
      .subscribe(
        res => {
          let token = res['resultado'].token;
          this.router.navigate(['alterar-senha', token]);
        },
        error => {
          this.handeError(error);
        },
      );
  }

  handeError(error) {
    // console.log(error);

    swal.fire(
      getSwal(
        'error',
        'Código Inválido',
        'Verifique o código recebido no celular cadastrado e digite corretamente.',
        'ok',
        null,
      ),
    ).then(res => {
      this.router.navigate(['index']);
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    // this.subscription.unsubscribe();
  }
}
