import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cgf'
})

export class CgfPipe implements PipeTransform {
  transform(value: any): any {
    if(isNaN (parseInt(value))){
      return value
    }
    if(!isNaN (parseInt(value)) && value.length >= 8 ){
      if(parseInt(value.substr(0, 1))==0){
        return `${value.substr(0, 2)}.${value.substr(2, 3)}.${value.substr(5, 3)}-${value.substr(8, 1)}`
      }else{
        return '0'+`${value.substr(0, 1)}.${value.substr(1, 3)}.${value.substr(4, 3)}-${value.substr(7, 1)}`
      }
    }else{
      return '0'+`${value.substr(0, 1)}.${value.substr(1, 7)}`
    }
  }
}