import { Component, OnInit } from '@angular/core';
import { WizardService } from '../../../../../../service/wizard/wizard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'resumo-procurador-app',
  templateUrl: 'procurador.component.html',
  styleUrls: ['./procurador.component.scss'],
})
export class ResumoProcuradorComponent implements OnInit {
  routeSub: Subscription;
  perfil: string = '';
  index: number = 4;
  data = {};
  infoBasica = null;
  repLegal = null;
  procurador = null;
  prefeituras = null;
  tipo = null;
  semOutorga: boolean = false;

  constructor(
    private wizardService: WizardService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    // console.log('legn conta:', this.wizardService.userData['infoBasica']);

    // this.data = JSON.parse(localStorage.getItem('data'));
    // this.wizardService.userData = this.data;



      this.data = this.wizardService.userData;
      this.infoBasica = this.data['infoBasica'];
      this.repLegal = this.data['representanteLegal'];
      this.procurador = this.data['procurador'];
      this.prefeituras = this.data['prefeituras'];
      this.tipo = this.data['tipo'];
      this.perfil = this.data['perfil'];

      // console.log('this.data', this.data);
      // console.log('this.procurador', this.procurador);
      
    if (this.procurador['semOutorga']) this.semOutorga = true;
    if (this.perfil === 'contribuinte') this.tipo = 'juridica';
  }

  edit(index, title: string) {
    this.wizardService.updateIndex(index);
    let profile = this.perfil;
    switch (title) {
      case 'prefeituras':
        this.router.navigate(['cadastro', profile, title]);
        break;
      case 'dados':
        this.perfil === 'contribuinte'
          ? this.router.navigate(['cadastro/contribuinte/dados'])
          : this.router.navigate(['cadastro/procurador/dados-procurador']);
        break;
      case 'representante':
        console.log(profile);
        this.router.navigate(['cadastro', profile, title]);
        break;
      default:
        this.router.navigate(['cadastro', profile, 'resumo']);
        break;
    }
  }
}
