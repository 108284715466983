import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icones-validacao',
  templateUrl: 'icones-validacao.component.html',
  styleUrls: ['./icones-validacao.component.scss'],
})
export class IconesValidacaoComponent {
  @Input() error!: any;
  
  constructor(

  ) {}

}