import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'searchProcuracaoSituacao',
})
export class SearchProcuracaoSituacaoPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    // searchText = searchText.toLowerCase();

    searchText = searchText;

    return items.filter(it => {
      return (
        it.situacao.codigo == searchText
      )
      
    });
  }
}
