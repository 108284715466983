import { version } from './version';

export const environment = {
  BASE_URL: 'https://api.eiconbrasil.com.br/dte-servicos/plataformadte/v1',
  production: true,
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  firebaseConfig: {
    apiKey: "AIzaSyCfkugEPINGr9sHKTa0anJq1HUkiNCRvbg",
    authDomain: "dte-id.firebaseapp.com",
    projectId: "dte-id",
    storageBucket: "dte-id.appspot.com",
    messagingSenderId: "619843953278",
    appId: "1:619843953278:web:7251e3a12840932a8b2e2f",
    measurementId: "G-ST0XXRQ7HP"
  },
  notificationApi: "https://dtealerta.eiconbrasil.com.br/pushSubscription/registrar"
};
