import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CertificadoService } from '../../../service/certificado/certificado.service';
import { SessionService } from '../../../service/session/session.service';
import { LoginService } from '../../../service/login/login.service';
import { UserService } from '../../../service/user-service/user.service';
import { LacunaWebPKI, CertificateModel, ExceptionModel } from 'web-pki';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { DataTable } from 'ng2-data-table';
import swal from 'sweetalert2';
import { getLocaleNumberSymbol } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { getSwal } from '../../../utils/swalObject';

@Component({
  selector: 'app-e-cnpj',
  templateUrl: 'e-cnpj.component.html',
  styleUrls: ['./e-cnpj.component.scss'],
  providers: [DataTable]
})
export class ECnpjComponent implements OnInit {
  empresas: Array<any> = [];
  userInfo: any = null;
  allow: boolean = false;
  loading: boolean = true;
  certificado: any = null;
  private pki: LacunaWebPKI;
  codigoEmpresa: number = null;
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  labels;
  itemsPerView: number = 200;
  selectedEntities: any[];
  data: Object = {};


  constructor(
    private sessionService: SessionService,
    private loginService: LoginService,
    private userService: UserService,
    private ngZone: NgZone,
    private router: Router,
    private config: CertificadoService,
    private translate: TranslateService,
  ) {
    this.pki = new LacunaWebPKI(config.webPkiLicense);
    this.setLabels();
  }

  ngOnInit() {
    this.pki.init({
      ngZone: this.ngZone,
      ready: this.onWebPkiReady,
    });
    this.userInfo = this.sessionService.getSession();
    this.empresas = this.userInfo.empresas;
    this.data = this.userInfo.empresas;
    this.empresas.forEach(elem => {
      elem['selected'] = false;
    });
    // localStorage.setItem();

    let empresa = [];
    empresa.push(this.data);
    localStorage.setItem('PossuiFilial',empresa[0].length);
  }

  private onWebPkiReady = () => {
    this.certificado = this.loginService.dadosCertificado['certificado'];
    console.log(this.certificado);
    this.loading = false;
  };

  select(empresa) {
    empresa.selected = true;
    this.empresas.map(elem => {
      if (elem.codigo !== empresa.codigo) elem['selected'] = false;
    });
    this.allow = true;
    this.codigoEmpresa = empresa.codigo;
  }

  proximo() {
    this.loading = true;
    this.loginService
      .getNonce()
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        const thumbprint = this.certificado['thumbprint'];
        this.pki.readCertificate(thumbprint).success(certEncoded => {
          this.pki
            .signData({
              thumbprint: thumbprint,
              data: res.resultado['nonce'],
              digestAlgorithm: res.resultado['digestAlgorithm'],
            })
            .success(signature => {
              this.loginService
                .loginViaCertificadoEmpresas(
                  res.resultado['nonce'],
                  certEncoded,
                  signature,
                  this.codigoEmpresa,

                )
                .subscribe(
                  res => this.handleResponse(res),
                  error => this.handleError(error),
                );
            });
        });
      });
  }

  handleResponse(res) {
    this.userService.sendInfo({ loggedIn: false, ...res.resultado });
    this.router.navigate(['../perfis']);
  }

  handleError(error) {
    swal.fire(getSwal('error', 'Ops!', error.excecao.recomendacao, 'ok', null)).then(
      res => {
        this.router.navigate(['../certificado']);
      },
    );
  }

  setSelectedEntities($event: any) {
    this.selectedEntities = $event;
  }

  setItemsPerView(num: number) {
    this.itemsPerView = num;
  }
  setLabels() {
    this.labels = {
      cgf: this.translate.instant('GLOBAIS.INSCRICAO'),
    };
  }
}
