import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';

@Injectable()
export class VersaoService {

    url: string = environment.BASE_URL;

    constructor(private http: HttpClient) { }

    obterVersao(): Observable<any> {
        return this.http.get(`${this.url}/versao`).map(data => {
            return data;
        });
    }

}
