import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConsultaUsuarioService } from '../../../../../../service/consulta-usuario/consultar.user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pop-up-termo-adesao',
  templateUrl: './pop-up-termo-adesao.component.html',
  styleUrls: ['./pop-up-termo-adesao.component.scss']
})
export class PopUpTermoAdesaoComponent implements OnInit {
  @Input() data:  any= [];
  @Output() onClosePopUpTermoAdesao: EventEmitter<any> = new EventEmitter<any>();
  acordo: boolean= false;
  
  constructor(
    private consultaUsuario: ConsultaUsuarioService,
    private router:Router
    ) {

     }

  ngOnInit() {
  }

  fecharPopUpTermoAdesao() {
    this.onClosePopUpTermoAdesao.emit(false);
  }

  assinarDigitalmente(){
    this.router.navigate(['/cadastro/contribuinte/certificado/'+this.data.codigoUsuario+'/'+this.data.cliente]);
  }

}




