import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/throw';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AplicacoesService {
  url: string = environment.BASE_URL;
  //obter lista de aplicações
  private aplicacoes = new Subject<any>();
  aplicacoes$ = this.aplicacoes.asObservable();
  //obter a aplicação selecionada, seja em inclusao ou edicao
  private aplicacao = new BehaviorSubject<any>('');
  aplicacao$ = this.aplicacao.asObservable();
  //obter uma funcionalidade, seja em inclusao ou edicao
  private funcionalidade = new BehaviorSubject<any>('');
  funcionalidade$ = this.funcionalidade.asObservable();
  private editFuncionalidade = new BehaviorSubject<any>('');
  editFuncionalidade$ = this.editFuncionalidade.asObservable();
  private funcionalidades = [];

  constructor(private http: HttpClient) {}

  obterDadosGraficos(): Observable<any> {
    const registradas = this.http
      .get(`${this.url}/aplicacoes/quantidadeAplicacoesParaComunicado`)
      .map(res => res);
    const contribuinte = this.http
      .get(`${this.url}/aplicacoes/quantidadeAplicacoesAcessiveisViaPortal`)
      .map(res => res);
    const auditor = this.http
      .get(`${this.url}/aplicacoes/quantidadeAplicacoesAcessiveisViaAuditor`)
      .map(res => res);
    return Observable.forkJoin([registradas, contribuinte, auditor]);
  }

  obterDadosFormaAcesso(): Observable<any> {
    const formasAcesso = this.http
      .get(`${this.url}/formasAcesso/ativos`)
      .map(res => res);
    const perfisAcesso = this.http
      .get(`${this.url}/perfisAcesso/ativos`)
      .map(res => res);
    const perfisUsuario = this.http
      .get(`${this.url}/tiposProcuracao/ativos`)
      .map(res => res);
    return Observable.forkJoin([formasAcesso, perfisAcesso, perfisUsuario]);
  }

  setAplicacao(aplicacao) {
    this.aplicacao.next(aplicacao);
  }

  registrarAcesso(aplicacao: number) {
    return this.http
      .get(`${this.url}/aplicacoes/${aplicacao}/registrarAcesso`)
      .map(res => res);
  }

  setFuncionalidade(funcionalidade: any) {
    if (!this.funcionalidades.find(o => o.codigo === funcionalidade.codigo)) {
      this.funcionalidades.push(funcionalidade);
    } else {
      const index = this.funcionalidades.findIndex(
        obj => obj.codigo === funcionalidade.codigo
      );
      this.funcionalidades[index] = funcionalidade;
    }
    // this.funcionalidades.push(funcionalidade);
    this.funcionalidade.next(this.funcionalidades);
  }

  setFuncionalidadesOnEdit(funcionalidades) {
    this.funcionalidades = funcionalidades;
    this.funcionalidade.next(this.funcionalidades);
  }

  resetFuncionalidade() {
    this.funcionalidades = [];
    this.funcionalidade.next(this.funcionalidades);
  }

  editarFuncionalidade(funcionalidade: any) {
    this.editFuncionalidade.next(funcionalidade);
  }

  getData(): Observable<any> {
    const perfis = this.http.get(`${this.url}/perfisAcesso`).map(res => res);
    const formasAcesso = this.http
      .get(`${this.url}/formasAcesso/ativos`)
      .map(res => res);
    return Observable.forkJoin([perfis, formasAcesso]);
  }

  getAplicacoes(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/aplicacoes`, { params: params })
      .map(data => {
        const body = data;
        this.aplicacoes.next(body);
        return body;
      });
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  criaAplicacao(params: any): Observable<any> {
    const aplicacao = params;
    return this.http.post(`${this.url}/aplicacoes`, aplicacao).map(data => {
      const body = data;
      return body;
    });
  }

  editaAplicacao(code: number, params: any): Observable<any> {
    const aplicacao = params;
    const codigo = code;
    return this.http
      .put(`${this.url}/aplicacoes/${codigo}`, aplicacao)
      .map(data => {
        const body = data;
        return body;
      });
  }

  obterAplicacao(id: number): Observable<any> {
    return this.http.get(`${this.url}/aplicacoes/${id}`).map(data => {
      const body = data;
      this.setFuncionalidadesOnEdit(data['resultado'].funcionalidades);
      return body;
    });
  }

  obterAplicacaoComAcessoIrrestrito() {
    return this.http
      .get(`${this.url}/aplicacoesIrrestritasContribuinte`)
      .map(data => {
        const body = data;
        return body;
      });
  }

  obterAplicacaoComAcessoRestrito() {
    return this.http
      .get(`${this.url}/aplicacoesRestritasContribuinte`)
      .map(data => {
        const body = data;
        return body;
      });
  }
}
