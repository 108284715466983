import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RegistroAcoesComponent } from './registro-acoes/registro-acoes.component';
import { AuthGuardService } from '../../service/auth-guard/auth-guard.service';

const routes: Routes = [
  {
    path: 'registro',
    canActivate: [AuthGuardService],
    component: RegistroAcoesComponent,
    data: { breadcrumb: 'Histórico de Acessos', modulo: '2002' }
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegistroAcoesRoutingModule {}
