import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse  } from "@angular/common/http";
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

import { environment } from '../../../environments/environment'

@Injectable()
export class RecuperarSenhaService {
  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  verificarUsuario(credentials): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        cpfCnpj : credentials.cpf , 
        email: credentials.email,
        ddd : credentials.ddd , 
        celular: credentials.celular
    }});
    return this.http.get(`${this.url}/verificarUsuario`, {params: params}).map(data => {
        let body = data;
        return body;
      })
  }

  recuperarSenha(codigoUsuario, endpoint): Observable<any> {
    let params = new HttpParams().set('codigoUsuario', codigoUsuario);
    return this.http.get(`${this.url}/${endpoint}`, {params: params}).map(data => { return data })
  }
  
  ValidacaoPorEmailSms(codigoUsuario): Observable<any> { 
    let params = new HttpParams().set('codigoUsuario', codigoUsuario);
    return this.http.get(`${this.url}/solicitarValidacaoPorEmailSms`, { params: params }).map(data => { return data })
  }
  
  validarCodigoSMS(codigoUsuario, codigoVerificacao){
    const params = new HttpParams({
      fromObject: {
        codigoUsuario  : codigoUsuario, 
        codigoVerificacao : codigoVerificacao
    }});
    return this.http.get(`${this.url}/validarCodigoSms`, {params: params}).map(data => { return data })
  }

  validarCodigoEmail(codigoUsuario, codigoVerificacao) {
    const params = new HttpParams({
      fromObject: {
        codigoUsuario: codigoUsuario,
        codigoVerificacao: codigoVerificacao
      }
    });
    return this.http.get(`${this.url}/validarCodigoEmail`, {params: params}).map(data => { return data })
  }

  obterDadosViaToken(token): Observable<any> {
    let params = new HttpParams().set('tokenTemporal', token);
    return this.http.get(`${this.url}/obterDadosTokenTemporal`, {params: params}).map(data => { return data })
  }

  redefinirSenha(tokenTemporal, senha){
    const params = new HttpParams({
      fromObject: {
        tokenTemporal  : tokenTemporal, 
        senha : senha,
    }});
    return this.http.get(`${this.url}/redefinirSenha`, {params: params}).map(data => { return data })
  }



}