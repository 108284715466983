import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  ViewChildren
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { OutorgarProcuracaoService } from '../../../../../service/procuracoes/outorgar-procuracao.service';
import { ProcuracoesService } from '../../../../../service/procuracoes/procuracoes.service';
import { PerfilDataService } from '../../../../../service/perfil/perfil.service';
import { UserService } from '../../../../../service/user-service/user.service';

import moment from 'moment-timezone';
import { Moment } from 'moment';

import swal from 'sweetalert2';

import { TranslateService } from '@ngx-translate/core';
import { getSwal } from '../../../../../utils/swalObject';

import { TreeviewItem } from 'ngx-treeview';

import { TreeviewI18n } from 'ngx-treeview';
import { DefaultTreeviewI18n } from '../../../default-treeview-i18n';
import { Usuario } from '../../../../../model/usuario';

@Component({
  selector: 'app-outorgar',
  templateUrl: './outorgar.component.html',
  styleUrls: ['./outorgar.component.scss'],
  providers: [{ provide: TreeviewI18n, useClass: DefaultTreeviewI18n }]
})
export class OutorgarComponent implements OnInit {
  @ViewChildren('sistemasLista') sistemasLista: any;
  private outorgarForm: FormGroup;
  private outorgarFormCadastro: FormGroup;
  codigoProcuracao: any = null;
  codigoUsuario: number = null;
  perfisNaoDelegados: any = null;
  procurador: Object = null;
  tiposProcuracao = [];
  opcaoPapel: Object = null;
  sistemas = [];
  abrangencias: Array<any> = [];
  funcionalidades = [];
  selectedItem: number = null;
  loading = false;
  papel = -1;
  checked: boolean = true;
  validationMinDate: moment.Moment = moment();
  inicio: moment.Moment = moment();
  limiteFim: moment.Moment = moment();
  objCadastro: Object = null;
  modalElement: any;
  exibirModalCadastro: boolean = false;
  cpfCnpj: any;
  nomeRazaoSocial: any;
  email: any;
  celular: any;
  razaoSocial: any;
  userData: any;
  fim: any;
  oab: any;
  seccionalOab: any;
  situacaoOab: any;
  labels: any;
  dataFinalInferior: boolean = false;
  dataFinalPosteriorLimite: boolean = false;
  seccionalOabInvalida: boolean = false;
  public requireOAB: boolean = false;
  public semMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
  public menasMask = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  public cpfMask = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/
  ];
  public cnpjMask = [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/
  ];
  items: Array<TreeviewItem> = [];
  selectedItems = [];

  config = {
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private outorgarProcuracaoService: OutorgarProcuracaoService,
    private procuracoesService: ProcuracoesService,
    private translate: TranslateService,
    private renderer: Renderer2,
    private perfilDataService: PerfilDataService,
    private userService: UserService
  ) {
    this.criarFormulario();
    this.criarFormularioCadastro();
    this.setLabels();
  }

  ngOnInit() {
    this.outorgarProcuracaoService
      .getData()
      .subscribe(res => this.handleResponse(res), error => console.log(error));

    this.userData = this.userService.getUserData();
  }

  onClickInputFim() {
    this.dataFinalInferior = false;
    this.dataFinalPosteriorLimite = false;
  }

  onSelectedChange(event) {
    this.selectedItems = event;
  }

  onFilterChange() {}

  onClickInputSeccionalOab() {
    this.seccionalOabInvalida = false;
  }

  onBlurInputSeccionalOab() {
    if (this.seccionalOab) {
      if (this.seccionalOab.length < 2) {
        this.seccionalOab = '';
        this.seccionalOabInvalida = true;
        return;
      }

      var regexp = new RegExp('^[a-zA-Z]+$'),
        test = regexp.test(this.seccionalOab);

      if (!test) {
        this.seccionalOab = '';
        this.seccionalOabInvalida = true;
        return;
      }

      this.seccionalOab = this.seccionalOab.toString();
    }
  }

  criarFormulario() {
    this.outorgarForm = this.formBuilder.group({
      cpfCnpj: ['', Validators.required],
      razaoSocial: ['', Validators.required],
      papel: ['-1', Validators.required],
      inicio: ['', Validators.required],
      fim: [''],
      oab: ['', Validators.required],
      seccionalOab: ['', Validators.required],
      situacaoOab: ['', Validators.nullValidator]
    });

    this.limiteFim.add(365, 'day');
    this.outorgarForm.controls.inicio.disable();
  }

  criarFormularioCadastro() {
    this.outorgarFormCadastro = this.formBuilder.group({
      cpfCnpj: ['', Validators.required],
      nomeRazaoSocial: ['', Validators.required],
      email: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(60)])
      ],
      ddd: [''],
      celular: ['']
    });
  }

  mask(): any {
    return {
      mask: value => {
        if (value.length <= 5) {
          return this.semMask;
        } else if (value.length <= 11) {
          return this.menasMask;
        } else if (value.length >= 12 && value.length <= 14) {
          return this.cpfMask;
        } else if (value.length >= 16 && value.length <= 18) {
          return this.cnpjMask;
        } else if (value.length <= 15) {
          return this.cpfMask;
        }
      },
      guide: false
    };
  }

  pesquisarProcurador() {
    if (!this.cpfCnpj || this.cpfCnpj.toString().length < 13) {
      return;
    }
    this.loading = true;
    this.outorgarForm.controls['fim'].disable();
    this.outorgarProcuracaoService.buscarProcurador(this.cpfCnpj).subscribe(
      res => {
        this.loading = false;
        let lResult: number = res.resultado.length;

        if (lResult != 0) {
          this.procurador = res.resultado[0];
          this.outorgarForm.controls['razaoSocial'].patchValue(
            this.procurador['usuario'].nomeRazaoSocial
          );

          this.codigoUsuario = this.procurador['usuario'].codigo;

          if (this.procurador['usuario'].detalhe) {
            this.oab = this.procurador['usuario'].detalhe.oab;
            this.seccionalOab = this.procurador['usuario'].detalhe.seccionalOab;
            this.situacaoOab = this.procurador['usuario'].detalhe.situacaoOab;
          } else {
            this.oab = null;
            this.seccionalOab = null;
            this.situacaoOab = null;
          }

          this.outorgarForm.controls['razaoSocial'].disable();
          this.outorgarForm.controls['fim'].enable();
        } else {
          this.outorgarForm.controls['razaoSocial'].patchValue('');

          swal.fire(
            getSwal(
              'success',
              'Procurador não encontrado!',
              'Deseja se cadastrar?',
              'Cadastrar',
              'Cancelar'
            )
          ).then(result => {
            if (result.value) {
              this.exibirModalCadastro = true;
            }
          });
        }
      },
      error => {
        this.loading = false;
        if (error.status === 400) {
          swal.fire(
            getSwal(
              'warning',
              'Atenção!',
              error.error.excecao.recomendacao,
              'ok',
              null
            )
          ).then(res => {
            this.cpfCnpj = '';
          });
        } else {
          swal.fire(
            getSwal('error', 'Ops!', 'CPF ou CNPJ Inexistentes!', 'ok', null)
          ).then(res => {});
        }
      }
    );
  }

  closeModal() {
    this.exibirModalCadastro = false;
  }

  handleResponse(response) {
    // this.sistemas = response[0].resultado;
    this.tiposProcuracao = response[1].resultado;

    this.route.paramMap.subscribe(params => {
      this.codigoProcuracao = params.get('procuracao');

      // this.config.hasAllCheckBox = this.codigoProcuracao ? false : true;

      // this.sistemas.forEach(sistema => {
      //   const item = {
      //     text: sistema.descricao,
      //     value: sistema.codigo,
      //     checked: false,
      //     disabled: this.codigoProcuracao ? true : false,
      //     children: []
      //   };
      //   console.log('sistema.funcionalidades:',sistema.funcionalidades);
      //   sistema.funcionalidades.forEach(funcionalidade => {
      //     item.children.push({
      //       text: funcionalidade.descricao,
      //       value: funcionalidade.codigo,
      //       disabled: this.codigoProcuracao ? true : false,
      //       checked: false
      //     });
      //   });

      //   item.children.push({
      //     text: 'Opções futuras do sistema',
      //     value: '',
      //     disabled: this.codigoProcuracao ? true : false,
      //     checked: false
      //   });

      //   this.items.push(new TreeviewItem(item));
      // });

      if (this.codigoProcuracao) {
        this.procuracoesService
          .getProcuracao(this.codigoProcuracao)
          .subscribe(data => {
            this.cpfCnpj = data.resultado.procurador.usuario.cpfCnpj;
            this.razaoSocial =
              data.resultado.procurador.usuario.nomeRazaoSocial;
            this.email = data.resultado.procurador.usuario.email;
            this.celular = data.resultado.procurador.usuario.celular;
            this.inicio = data.resultado.inicio;
            const fim = moment(data.resultado.fim).format('DD/MM/YYYY');
            this.fim = fim !== 'Invalid date' ? fim : '';
            this.papel = data.resultado.tipoProcuracao.codigo;
            this.perfisNaoDelegados = data.resultado.perfisNaoDelegados;

            this.perfisNaoDelegados.forEach(perfil => {
              this.selectedItems.push(perfil.funcionalidade.codigo);
            });

            // this.items = [];

            // this.sistemas.forEach(sistema => {
            //   const item = {
            //     text: sistema.descricao,
            //     value: sistema.codigo,
            //     checked: !this.selectedItems.includes(sistema.codigo),
            //     disabled: this.codigoProcuracao ? true : false,
            //     children: []
            //   };

            //   sistema.funcionalidades.forEach(funcionalidade => {
            //     item.children.push({
            //       text: funcionalidade.descricao,
            //       value: funcionalidade.codigo,
            //       disabled: this.codigoProcuracao ? true : false,
            //       checked: !this.selectedItems.includes(funcionalidade.codigo)
            //     });
            //   });

            //   item.children.push({
            //     text: 'Opções futuras do sistema',
            //     value: '',
            //     disabled: this.codigoProcuracao ? true : false,
            //     checked: false
            //   });

            //   this.items.push(new TreeviewItem(item));
            // });
          });
      } else {
        this.formatarFuncionalidades();
      }
    });
  }

  formatarFuncionalidades() {
    this.sistemas.forEach(element => {
      if (element.funcionalidades.length >= 1) {
        element.funcionalidades.forEach(element => {
          element['checked'] = true;
        });
      }
    });
  }

  selectPapel(e: any) {
    const codigo = parseInt(e, 10);
    // console.log('select: ', e);

    this.opcaoPapel = this.tiposProcuracao.filter(
      elem => elem.codigo === codigo
    )[0];

    this.outorgarProcuracaoService
      .getFuncionalidadeAutorizadasProcuracao(codigo)
      .subscribe(data => {
        console.log('data:', data);
        this.config.hasAllCheckBox = this.codigoProcuracao ? false : true;
        this.items = [];
        this.sistemas = data.resultado;
        this.sistemas.forEach(sistema => {
          const item = {
            text: sistema.descricao,
            value: sistema.codigo,
            checked: this.codigoProcuracao
              ? !this.selectedItems.includes(sistema.codigo)
              : false,
            disabled: this.codigoProcuracao ? true : false,
            children: []
          };

          sistema.funcionalidades.forEach(funcionalidade => {
            item.children.push({
              text: funcionalidade.descricao,
              value: funcionalidade.codigo,
              disabled: this.codigoProcuracao ? true : false,
              checked: this.codigoProcuracao
                ? !this.selectedItems.includes(funcionalidade.codigo)
                : false
            });
          });

          this.items.push(new TreeviewItem(item));
        });
      });

    if (codigo === 3) {
      this.requireOAB = true;
      this.outorgarForm.controls['oab'].enable();
      this.outorgarForm.controls['seccionalOab'].enable();
    } else {
      this.requireOAB = false;
      this.outorgarForm.controls['oab'].disable();
      this.outorgarForm.controls['seccionalOab'].disable();
    }
  }

  onSistemasChange(sistema, e: any) {
    sistema.funcionalidades.forEach(element => {
      if (
        element.codigo !== 76 &&
        element.codigo !== 75 &&
        element.codigo !== 74
      ) {
        if (!e.target.checked) {
          element.checked = false;
          this.funcionalidades.push(element);
        } else {
          element.checked = true;
          this.funcionalidades = this.funcionalidades.filter(
            el => el.codigo !== element.codigo
          );
        }
      }
    });
  }

  onSistemasFunctionsChange(
    item: Object,
    funcionalidade: Object,
    index: number
  ) {
    let currentCheck = this.sistemasLista._results[0].nativeElement.children[
      index
    ].children[0].children[0];
    let curentFunctions = this.sistemas[Math.round(this.sistemas.indexOf(item))]
      .funcionalidades;
    let index_Cf = curentFunctions.indexOf(funcionalidade);
    let checkedRow: boolean[] = [];
    curentFunctions[index_Cf]['checked']
      ? (curentFunctions[index_Cf]['checked'] = false)
      : (curentFunctions[index_Cf]['checked'] = true);
    curentFunctions.forEach(res => {
      checkedRow.push(res.checked);
    });
    checkedRow.includes(true)
      ? this.renderer.setProperty(currentCheck, 'checked', 'checked')
      : this.renderer.setProperty(currentCheck, 'checked', '');
  }

  expandirSistema(index: number) {
    if (index !== this.selectedItem) {
      this.selectedItem = index;
    }
  }

  onFuncionalidadesChange(funcionalidade) {
    this.sistemas.forEach(element => {
      element.funcionalidades.forEach(element => {
        if (element.codigo === funcionalidade.codigo) {
          element.checked = !element.checked;
        }
        if (!element.checked) {
          this.funcionalidades.push(element);
        } else {
          this.funcionalidades = this.funcionalidades.filter(
            el => el.checked === true
          );
        }
      });
    });
  }

  onFimChange(event) {
    // console.log(event);
  }

  onSubmitCadastro() {
    let nome = this.outorgarFormCadastro.getRawValue().nomeRazaoSocial;
    let cpfCnpj = this.outorgarFormCadastro.getRawValue().cpfCnpj;
    let email = this.outorgarFormCadastro.getRawValue().email;
    let telefone = this.outorgarFormCadastro.getRawValue().celular;
    let ddd = telefone.substring(0, 2);
    let celular = telefone.substr(2, 9);
    var obj = {
      cpfCnpj: cpfCnpj,
      nomeRazaoSocial: nome,
      email: email,
      ddd: ddd,
      celular: celular
    };

    this.outorgarProcuracaoService
      .salvarUsuarioProcuracao(obj)
      .subscribe(res => {
        this.cpfCnpj = cpfCnpj;
        this.pesquisarProcurador();
        this.outorgarForm.controls['fim'].enable();
        this.closeModal();
      });
  }

  checkRole(role: number): void {
    window.alert(role);
  }

  gravarDadosUsuario() {
    if (!this.selectedItems.length) {
      swal.fire(
        getSwal(
          'error',
          'Ops!',
          'Necessário no mínimo um serviço para outorgar a procuração.',
          'ok',
          null
        )
      ).then(res => {});
      return;
    }

    let obj = this.outorgarForm.getRawValue();

    obj['funcionalidades'] = [];

    this.sistemas.forEach(sistema => {
      sistema.funcionalidades.forEach(funcionalidade => {
        if (!this.selectedItems.includes(funcionalidade.codigo)) {
          obj['funcionalidades'].push(funcionalidade);
        }
      });
    });

    if (!obj['funcionalidades'].length) {
      obj['funcionalidades'] = null;
    }

    obj['tipoProcuracao'] = this.opcaoPapel;
    obj['procurador'] = this.procurador;
    obj['inicio'] = moment(obj['inicio'], 'DD-MM-YYYY').format();
    obj['fim'] = obj['fim'] ? moment(obj['fim'], 'DD-MM-YYYY').format() : null;
    if (obj['procurador'] === null) {
      let nome = this.outorgarFormCadastro.getRawValue().nomeRazaoSocial;
      let cpfCnpj = this.outorgarFormCadastro.getRawValue().cpfCnpj;
      let email = this.outorgarFormCadastro.getRawValue().email;
      let telefone = this.outorgarFormCadastro.getRawValue().celular;
      let ddd = telefone.substring(0, 2);
      let celular = telefone.substr(2, 9);
      obj['procurador'] = {
        usuario: {
          nomeRazaoSocial: nome,
          cpfCnpj: cpfCnpj,
          email: email,
          celular: celular,
          ddd: ddd
        }
      };
    }
    this.outorgarProcuracaoService.outorgarData = obj;
    this.router.navigate(['procuracao/certificados']);
  }

  onSubmit() {
    let cpfcnpjsemPonto = this.cpfCnpj.replace(/[.-\s]/g, '').replace('/', '');
    if (this.userData['usuario']['cpfCnpj'].toString() === cpfcnpjsemPonto) {
      swal.fire(
        getSwal(
          'error',
          'Ops!',
          'Outorgante e Procurador não podem possuir o mesmo CPF/CNPJ',
          'ok',
          null
        )
      ).then(res => {});
      return;
    }

    if (this.requireOAB) {
      let obj = this.outorgarForm.getRawValue();

      this.perfilDataService.getUSer(this.codigoUsuario).subscribe(res => {
        let usuario = res.resultado;

        if (usuario.detalhe) {
          usuario.detalhe.oab = obj['oab'];
          usuario.detalhe.seccionalOab = obj['seccionalOab'];
        } else {
          usuario.detalhe = {
            oab: obj['oab'],
            seccionalOab: obj['seccionalOab']
          };
        }
        this.perfilDataService.editarUsuario(usuario).subscribe(res => {
          this.gravarDadosUsuario();
        });
      });
    } else {
      this.gravarDadosUsuario();
    }
  }

  setLabels() {
    this.labels = {
      title: this.translate.instant('PROCURACAO.TITLEOUTORGAR'),
      cpfCnpj: this.translate.instant('GLOBAIS.CPFCNPJ'),
      nomeRazaoSocial: this.translate.instant('GLOBAIS.NOMERAZAOSOCIAL'),
      email: this.translate.instant('GLOBAIS.EMAIL'),
      celular: this.translate.instant('GLOBAIS.CELULAR'),
      cadastrar: this.translate.instant('GLOBAIS.CADASTRAR'),
      dataInicial: this.translate.instant('GLOBAIS.DATAINICIAL'),
      dataFinal: this.translate.instant('GLOBAIS.DATAFINAL'),
      papel: this.translate.instant('GLOBAIS.PERFIL'),
      selecione: this.translate.instant('GLOBAIS.SELECIONE'),
      abrangencias: this.translate.instant('GLOBAIS.ABRANGENCIAS'),
      abrangenciasText: this.translate.instant('PROCURACAO.ABRANGENCIASTEXT'),
      proximo: this.translate.instant('GLOBAIS.PROXIMO'),
      desistir: this.translate.instant('GLOBAIS.DESISTIR'),
      cadastrarProcurador: this.translate.instant('PROCURACAO.CADASTRARPROCURADOR')
    };
  }
}
