import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CaixaPostalService } from '../../../../../service/caixa-postal/caixa-postal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pop-up-contribuinte',
  templateUrl: 'pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
})
export class PopUpContribuinteComponent implements OnInit {
  @Input() data: Object = {};
  @Output() onClosePopUp: EventEmitter<any> = new EventEmitter<any>();

  active: number = 0;
  labels;

  constructor(
    private caixaPostalService: CaixaPostalService,
    private translate: TranslateService,
  ) {
    this.setLabels();
  }

  ngOnInit() {}

  fecharPopUp() {
    this.onClosePopUp.emit(false);
  }
  setLabels() {
    this.labels = {
      cgf: this.translate.instant('GLOBAIS.INSCRICAO'),
    };
  }
}
