import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PerfilDataService } from '../../../service/perfil/perfil.service';
import { UserService } from '../../../service/user-service/user.service';
import { LoginService } from '../../../service/login/login.service';
import { ProcuracoesService } from '../../../service/procuracoes/procuracoes.service';
import { SessionService } from '../../../service/session/session.service';

import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { getSwal } from '../../../utils/swalObject';
import { OutorgarProcuracaoService } from '../../../service/procuracoes/outorgar-procuracao.service';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import moment from 'moment-timezone';
import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-procuracao',
  templateUrl: 'procuracao.component.html',
  styleUrls: ['./procuracao.component.scss']
})
export class ProcuracaoComponent implements OnInit {
  procuracoes = [];
  procuracoesTable = [];
  dataProcuracoes: object = {}
  teste = '';
  procuracoesFiltered = [];
  procuracoesFiltered2 = [];
  cliente: Object = {};
  procuracao: Object = {};
  searchForm: FormGroup;
  searchText = '';
  searchTextSituacao = '';
  allow: boolean = false;
  userInfo: Object = {};
  labels: any;
  showPage: boolean = false;
  selectAceite: boolean = false;
  itemsPerView: number = 200;
  selectedEntities: any[];
  clicked: boolean = false;
  loading: boolean = true;
  termoProcuracao: any;
  exigeTermoProcuracao: boolean = false;
  exibirModalTermoProcuracao: boolean = false;
  dataCurta: any = moment(new Date()).format('DD/MM/YYYY'); 
  dataLonga: any = moment().format('LL');
  termoProcuracaoOriginal: any;
  codigoCliente: any;
  codigoProcuracao: any;
  loadingProcuracao: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private data: PerfilDataService,
    private userService: UserService,
    private sessionService: SessionService,
    private loginService: LoginService,
    private translate: TranslateService,
    private procuracoesService: ProcuracoesService,
    private outorgarProcuracaoService: OutorgarProcuracaoService,
    private cadastroService: CadastroService,
    private sanitizer: DomSanitizer
  ) {
    this.setLabels();
  }

  ngOnInit() {
    // this.criarFormulario();
    this.cliente = this.data.storage;
    this.userInfo = this.sessionService.getSession();
    this.buscarProcuracoes();
    this.obterTermoProcuracao();
  }

  obterTermoProcuracao(){
    this.cadastroService.clientes().subscribe(res => {
      this.codigoCliente = res.resultado[0].codigo;
      this.termoProcuracaoOriginal = res.resultado[0].configuracao.termoProcuracao.texto;
      this.exigeTermoProcuracao = res.resultado[0].configuracao.exigeTermoProcuracao;
    })
  }  

  exibeTermoProcuracao(data){
    this.codigoProcuracao = data.codigo;
    let procurador = data.procurador.usuario;
    let outorgante = data.outorgante;

    this.termoProcuracao = this.termoProcuracaoOriginal
      .replace(/@{id do contribuinte}/g, outorgante.cpfCnpj)
      .replace(/@{contribuinte}/g, outorgante.nomeRazaoSocial)
      .replace(/@{id do procurador}/g, procurador.cpfCnpj)
      .replace(/@{procurador}/g, procurador.nomeRazaoSocial)
      .replace(/@{data curta}/g, this.dataCurta)
      .replace(/@{data longa}/g, this.dataLonga)
      .replace(/null/g, "");
    
    this.termoProcuracao = this.sanitizer.bypassSecurityTrustHtml(this.termoProcuracao);  
    this.exibirModalTermoProcuracao = true;
  }

  closeModal(){
    this.exibirModalTermoProcuracao = false;
    this.termoProcuracao = this.termoProcuracaoOriginal;
  }

  exportarPDF(){
    console.log(this.codigoProcuracao);
    this.loadingProcuracao = true;
    this.procuracoesService.baixarTermoProcuracao(this.codigoProcuracao).subscribe(res=> {
      this.loadingProcuracao = false;
      var blob = new Blob([res], { type: 'application/pdf' });
      FileSaver.saveAs(blob, `Termo de Procuracao`);
    }, error => {
      this.loading = false;
      swal.fire(
        getSwal(
          'error',
          'Ops!',
          'Ocorreu um erro e não foi possível realizar a exportação, tente novamente mais tarde.',
          'ok',
          null,
        ),
      );
    },)
  }
  
  buscarProcuracoes() {
    this.loading=true;
    this.loginService
      .procuracoes()
      .subscribe(
        res => {
          this.handleResponse(res)
          this.loading=false;
        },
        error => {
          this.handleError(error)
          this.loading=false;
        
        }  
      );
  }

  aceitarProcuracao(codigoProcuracao: number) {
    this.procuracoesService.aceitarProcuracao(codigoProcuracao).subscribe(
      () => {
        this.buscarProcuracoes();
      },
      err => {
        console.log(err);
        this.buscarProcuracoes();
        this.clicked = false
      }
    );
  }

  cancelarProcuracao(procuracao: any) {
    this.router.navigate([`/certificados/login/` + procuracao.codigo + '/' + false]);
  }

  handleResponse(res: any) {
    console.log('teste data', res);
    if (res['resultado'].length === 0) {
      this.showPage = true;

      swal.fire(
        getSwal(
          'error',
          'Ops!',
          'Seu perfil não possui procurações outorgadas.',
          'ok',
          null
        )
      ).then(res => {
        this.router.navigate(['/index']);
      });
    } else if (
      res['resultado'].length === 1 &&
      res['resultado'][0].situacao.descricao === 'Válida'
    ) {
      window.localStorage.setItem(
        'hasletters',
        (res['resultado'].length === 1).toString()
      );
      this.procuracoes = res['resultado'];
      this.procuracoes = this.procuracoes.sort(this.compareCodigo);
      this.procuracoes.forEach(elem => {
        // procuracoesTable
        console.log('elem:', elem);
        elem['selected'] = false;
        if (
          elem['outorgante']['detalhe'] &&
          elem['outorgante']['detalhe'] !== null
        ) {
          elem['outorgante'].inscricao =
            elem['outorgante']['detalhe']['inscricaoMunicipal'] !== null
              ? elem['outorgante']['detalhe']['inscricaoMunicipal']
              : 'Não Informado';
        }

        elem['nomeRazaoSocial'] = elem.outorgante.nomeRazaoSocial;
        elem['inscricao']       = elem.outorgante.inscricao;
        elem['tipoProcuracaoOrder'] =elem.tipoProcuracao.descricao;
        elem['situacaoOrder'] =elem.situacao.descricao;
        this.procuracoesTable.push(elem);
        
      });
      this.procuracao = res['resultado'][0];
      this.proximo();
    } else {
      window.localStorage.setItem(
        'hasletters',
        (res['resultado'].length > 1).toString()
      ); 
      this.showPage = true;
      const procuracoes = res['resultado'];
      this.procuracoes = procuracoes.sort(this.compareCodigo);
      console.log('entrou aqui');

      this.procuracoes.map(procuracao => {
        if (procuracao.situacao.descricao !== 'Válida') {
          procuracao['inactive'] = true;
        }
      });
      this.procuracoes.forEach(elem => {
        
        elem['selected'] = false;
        if (
          elem['outorgante']['detalhe'] &&
          elem['outorgante']['detalhe'] !== null
        ) {
          elem['outorgante'].inscricao =
            elem['outorgante']['detalhe']['inscricaoMunicipal'] !== null
              ? elem['outorgante']['detalhe']['inscricaoMunicipal']
              : 'Não Informado';
        }

        elem['nomeRazaoSocial'] = elem.outorgante.nomeRazaoSocial;
        elem['inscricao']       = elem.outorgante.inscricao;
        elem['tipoProcuracaoOrder'] =elem.tipoProcuracao.descricao;
        elem['situacaoOrder'] =elem.situacao.descricao;
        this.procuracoesTable.push(elem);
        
      });
    }
    this.habilitaX();
  }

  handleError(error: any) {
    swal.fire(
      getSwal(
        'error',
        'Ops!',
        'Seu perfil não possui procurações outorgadas.',
        'ok',
        null
      )
    ).then(res => {
      this.router.navigate(['/index']);
    });
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      prefeitura: ['', Validators.required]
    });
  }

  orderData(val: any) {
    if (val === 'ascendente') {
      let asc = this.procuracoes;
      asc.sort(this.compare);
    } else {
      let desc = this.procuracoes;
      desc.sort(this.compare).reverse();
    }
  }

  searchTextChange(value) {
    this.procuracoesFiltered = [];
    this.searchText = value;
    // this.procuracoes.filter(value);
    this.procuracoes.map(procuracao => {
      this.searchText = this.searchText
        .replace('.', '')
        .replace('.', '')
        .replace('-', '')
        .replace('/', '');
      if (
        procuracao.outorgante.nomeRazaoSocial
          .toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        procuracao.outorgante.cpfCnpj
          .toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        procuracao.outorgante.inscricao
          .toLowerCase()
          .includes(this.searchText.toLowerCase()) ||
        ('0' + procuracao.outorgante.inscricao)
          .toLowerCase()
          .includes(this.searchText.toLowerCase())
      ) {
        this.searchTextSituacao = '';
        this.procuracoesFiltered.push(procuracao);
        // console.log('procuracao: ',procuracao);
        // return procuracao;
      }
    });
  }
  habilitaX() {
    this.procuracoes.map(procuracao => {
      if (procuracao.situacao.codigo === 3) {
        this.selectAceite = true;
      }
    });
  }

  searchTextChange2(value) {
    if (this.searchText) {
      this.procuracoesFiltered2 = [];
      this.procuracoesFiltered.map(procuracao => {
        if (procuracao.situacao.codigo == value) {
          this.procuracoesFiltered2.push(procuracao);
        }
      });
    } else {
      this.procuracoesFiltered2 = [];
      this.procuracoes.map(procuracao => {
        if (procuracao.situacao.codigo == value) {
          this.procuracoesFiltered2.push(procuracao);
        }
      });
    }
  }

  compare(a, b) {
    const nomeA = a.nome.toUpperCase();
    const nomeB = b.nome.toUpperCase();

    let comparison = 0;
    if (nomeA > nomeB) {
      comparison = 1;
    } else if (nomeA < nomeB) {
      comparison = -1;
    }
    return comparison;
  }

  compareCodigo(a, b) {
    if (a.codigo > b.codigo) {
      return -1;
    }
    if (a.codigo < b.codigo) {
      return 1;
    }
    return -1;
  }

  // compareInactive(a, b) {
  //   if (a.fim) {
  //     return 1;
  //   } else {
  //     return -1;
  //   }
  // }

  select(perfil: any) {
    perfil.selected = true;
    this.procuracao = perfil;

    this.procuracoes.map(elem => {
      elem['selected'] = elem.codigo === perfil.codigo ? true : false;
      this.allow = true;
    });
    if (perfil.situacao.codigo === 4) {
      this.allow = false;
    }
  }

  desistir() {
    if (this.userInfo['procuracao'] === null) {
      this.userService.sendInfo({ loggedIn: false });
      this.sessionService.clearSession();
      this.router.navigate(['/index']);
    } else {
      this.userInfo['loggedIn'] = true;
      this.userService.sendInfo({ ...this.userInfo });
      this.router.navigate(['/home']);
    }
  }

  proximo() {
    let codigoProcuracao = this.procuracao['codigo'];
    if (this.userInfo['procuracao'] === null) {
      this.loginService
        .atualizarSessao(codigoProcuracao)
        .then(res => {
          this.userService.sendInfo({
            loggedIn: true,
            qtdProcuracoes: this.procuracoes.length,
            ...res['resultado']
          });
          this.router.navigate(['/home']);
        })
        .catch(error => {
          this.router.navigate(['/']);
        });
    } else {
      this.loginService.trocarProcuracao(codigoProcuracao).subscribe(
        res => {
          this.userService.sendInfo({ loggedIn: true, ...res['resultado'] });
          this.router.navigate(['/home']);
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  setLabels() {
    this.labels = {
      aceitar: this.translate.instant('GLOBAIS.ACEITAR'),
      naoaceitar: this.translate.instant('GLOBAIS.NAOACEITAR'),
      acao: this.translate.instant('GLOBAIS.ACAO'),
      outorgante: this.translate.instant('GLOBAIS.OUTORGANTE'),
      cnpj: this.translate.instant('GLOBAIS.CNPJ'),
      inscricao: this.translate.instant('GLOBAIS.INSCRICAO'),
      semresultado: this.translate.instant('GLOBAIS.SEMRESULTADO'),
      desistir: this.translate.instant('GLOBAIS.DESISTIR'),
      razaosocial: this.translate.instant('GLOBAIS.RAZAOSOCIAL'),
      entrar: this.translate.instant('INDEX.ENTRAR'),
      tipoProcuracao: this.translate.instant('PROCURACAO.TIPOPROCURACAO'),
      razaoSocial: this.translate.instant('GLOBAIS.RAZAOSOCIAL'),
      searchPlaceHolder: this.translate.instant('PROCURACAO.BUSCA'),
      bemvindo: this.translate.instant('PROCURACAO.BEMVINDO'),
      escolha: this.translate.instant('PROCURACAO.ESCOLHA'),
      situacaoProcuracao: this.translate.instant('PROCURACAO.SITUACAOPROCURACAO')
    };
  }

  setSelectedEntities($event: any) {
    this.selectedEntities = $event;
  }

  setItemsPerView(num: number) {
    this.itemsPerView = num;
  }
}
