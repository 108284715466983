import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';
import { SessionService } from '../session/session.service';

import * as SockJS from 'sockjs-client';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from '../../../../node_modules/rxjs';

@Injectable()
export class AlertasService {
  url: string = environment.BASE_URL;
  private alerts = new Subject<object>();

  private protocolo = new BehaviorSubject<any>('');
  protocolo$ = this.protocolo.asObservable();

  constructor(private http: HttpClient) {}

  marcarComoLido(codigo, token) {
    const header = new HttpHeaders();
    header.append('Authorization', token);
    this.http
      .get(`${this.url}/alertas/${codigo}/marcarComoLido`, { headers: header })
      .map(data => {
        console.log('Teste: ', data);
        return data;
      })
      .subscribe();
  }

  updateAlerts(value: any) {
    this.alerts.next(value);
  }

  getUpdates() {
    return this.alerts.asObservable();
  }

  marcarTodosComoLido(token) {
    const header = new HttpHeaders();
    header.append('Authorization', token);
    this.http
      .get(`${this.url}/alertas/marcarTodosComoLido`, { headers: header })
      .map(data => {
        return data;
      })
      .subscribe();
  }
  
  getNaoLidos(token: string) {
    const header = new HttpHeaders();
    header.append('Authorization', token);
    return this.http
      .get(`${this.url}/alertas/naoLidos`, { headers: header })
      
  }

  setProtocolo(protocolo) {
    this.protocolo.next(parseInt(protocolo, 10));
  }
}
